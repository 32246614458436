import PublicLayout from 'src/layout/PublicLayout';
import { LayoutPaper } from 'src/layout';
import LoginForm from 'src/widgets/LoginForm';

export default function Login() {
    return (
        <LayoutPaper>
            <PublicLayout>
                <LoginForm />
            </PublicLayout>
        </LayoutPaper>
    );
}
