export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <g>
                <path
                    d="M14 21.5V21.5C15.8605 21.5 16.7908 21.5 17.5495 21.2772C19.3454 20.7499 20.7499 19.3454 21.2772 17.5495C21.5 16.7908 21.5 15.8605 21.5 14V10C21.5 8.13948 21.5 7.20922 21.2772 6.45047C20.7499 4.65457 19.3454 3.25011 17.5495 2.72279C16.7908 2.5 15.8605 2.5 14 2.5V2.5"
                    stroke="#1F1F1F"
                />
                <path
                    d="M18 12L2.5 12"
                    stroke="#1F1F1F"
                />
                <path
                    d="M8.5 18L3.20711 12.7071C2.81658 12.3166 2.81658 11.6834 3.20711 11.2929L8.5 6"
                    stroke="#1F1F1F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
);
