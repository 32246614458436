import { memo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

import ArrowLeft from 'src/icons/ArrowLeft';
import Cross from 'src/icons/Cross';

import { history } from 'src/utils/history';

import useStyles from './layout.styles';

import type { ReactNode } from 'react';

type LayoutProps = {
    children?: ReactNode;
    showBack?: boolean;
    showClose?: boolean;
    title?: string;
    showMyHeader?: boolean;
    dark?: boolean;
};

const Layout = ({ children, showBack, showClose, title = '', showMyHeader = true, dark = false }: LayoutProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {showMyHeader && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    height={48}
                    position="sticky"
                    top={0}
                    zIndex={zIndex.appBar}
                    bgcolor={'background.default'}
                >
                    <Box
                        width={48}
                        flexShrink={0}
                    >
                        {showBack && (
                            <IconButton
                                size="small"
                                onClick={history.goBack}
                                style={{
                                    width: 48,
                                    height: 48,
                                }}
                            >
                                <ArrowLeft />
                            </IconButton>
                        )}
                    </Box>
                    {title && (
                        <Box width={1}>
                            <Typography
                                variant="h4"
                                textAlign="center"
                            >
                                {title}
                            </Typography>
                        </Box>
                    )}
                    <Box
                        width={48}
                        flexShrink={0}
                    >
                        {showClose && (
                            <IconButton
                                size="small"
                                onClick={history.goBack}
                                style={{
                                    width: 48,
                                    height: 48,
                                }}
                            >
                                <Cross />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            )}
            <Box
                minHeight={showMyHeader ? 'calc(100dvh - 48px)' : '100%'}
                className={dark ? classes.container : classes.containerLight}
                px={2}
            >
                {children}
            </Box>
        </Box>
    );
};

export default memo(Layout);
