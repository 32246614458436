import { Switch, Route } from 'react-router-dom';

import NotFound404 from 'src/screens/NotFound404';

import ROUTES from './routes';
import MainRoute from './MainRoute';
import ProtectedRoute from './ProtectedRoute';

import type { RouteName } from './routes';

const routes = Object.keys(ROUTES);

export default function Router() {
    return (
        <Switch>
            <Route
                path="/"
                component={MainRoute}
                exact
            />
            {routes.map((key) => {
                const { component, path, exact, isPublic } = ROUTES[key as RouteName];
                if (isPublic) {
                    return (
                        <Route
                            key={key}
                            path={path}
                            exact={exact}
                            component={component}
                        />
                    );
                }

                return (
                    <ProtectedRoute
                        key={key}
                        path={path}
                        exact={exact}
                        component={component}
                    />
                );
            })}
            <Route
                path="/"
                component={NotFound404}
            />
        </Switch>
    );
}
