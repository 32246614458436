export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 2C5.11929 2 4 3.11929 4 4.5V8.5V9.5V10V19.5C4 20.8807 5.11929 22 6.5 22H19.5H20V21H19.5H19V17.5002C19.6072 17.0441 20 16.3179 20 15.5V4.5C20 3.11929 18.8807 2 17.5 2H6.5ZM18 17.95C17.8384 17.9828 17.6712 18 17.5 18H6.5C5.67157 18 5 18.6716 5 19.5C5 20.3284 5.67157 21 6.5 21H18V17.95ZM5 17.4998C5.41783 17.186 5.9372 17 6.5 17H17.5C18.3284 17 19 16.3284 19 15.5V4.5C19 3.67157 18.3284 3 17.5 3H6.5C5.67157 3 5 3.67157 5 4.5V8.5V9.5V10V17.4998Z"
                fill="#1F1F1F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7793 7.59326C12.684 7.24078 12.3637 6.99634 11.9986 6.99732C11.6351 6.99829 11.3171 7.24228 11.2222 7.59315L10.4353 10.5H13.5657L12.7793 7.59326ZM9.48789 14L10.1646 11.5H13.8362L14.5126 14H15.5485L13.7446 7.33211C13.531 6.54264 12.8137 5.99512 11.9959 5.99732C11.1817 5.99951 10.4696 6.546 10.2569 7.33186L8.4519 14H9.48789Z"
                fill="#1F1F1F"
            />
        </g>
    </svg>
);
