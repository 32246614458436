import globalAgent from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { AxiosPromise } from 'axios';
import type { IUserResponse, StepsType, UpdateOnboardingStepResponse, ChangeEmailConfirmRequest } from 'src/types/User';

export const updateOnboardingStep = (data: StepsType) =>
    globalAgent.post<UpdateOnboardingStepResponse>(URLS.UPDATE_ONBOARDING_STEP, { step: data });

export const getUser = (): AxiosPromise<IUserResponse> => globalAgent.get(URLS.USER);

export const changeEmail = (data: Pick<ChangeEmailConfirmRequest, 'new_email'>) =>
    globalAgent.post(URLS.CHANGE_EMAIL, data);

export const changeEmailConfirm = (data: ChangeEmailConfirmRequest) =>
    globalAgent.post(URLS.CHANGE_EMAIL_CONFIRM, data);
