export type Subscriber = EventListenerOrEventListenerObject & { unsubscribe(): void };
export type CallbackFunc<T = unknown> = (eventType: string, eventDetail: T) => void;

abstract class EventService {
    private static eventTarget: EventTarget;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static getInstance() {
        if (!this.eventTarget) {
            this.eventTarget = new EventTarget();
        }

        return this.eventTarget;
    }

    static subscribe<T = undefined>(type: string, callback: CallbackFunc<T>, once = false): Subscriber {
        const subscriber = (event: Event) => {
            callback(event.type, (event as CustomEvent)?.detail);
        };

        this.getInstance().addEventListener(type, subscriber, { once });

        subscriber.unsubscribe = () => {
            this.unsubscribe(type, subscriber);
        };

        return subscriber;
    }

    static unsubscribe = (type: string, callback: Subscriber): EventService => {
        this.getInstance().removeEventListener(type, callback);

        return this;
    };

    static dispatch<T>(type: string, data?: T): EventService {
        const event = new CustomEvent(type, { detail: data });

        this.getInstance().dispatchEvent(event);

        return this;
    }
}

export default EventService;
