import { create } from 'zustand';

type AppStore = {
    accordeonMenu: boolean,
    setAccordeonMenu: (value: boolean) => void;
};

export default create<AppStore>((set) => ({
    accordeonMenu: false,
    setAccordeonMenu: (value) => set({ accordeonMenu: value }),
}));