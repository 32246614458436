import globalAgent from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { AxiosPromise } from 'axios';
import type { Subscription, PromoCheckResponse, PaymentRequest, PaymentResponse } from 'src/types/Subscription';

export const getSubscriptions = (): AxiosPromise<Subscription[]> => {
    return globalAgent.get(URLS.SUBSCRIPTIONS);
};

export const checkPromocode = (promo_code: string): AxiosPromise<PromoCheckResponse> => {
    return globalAgent.post(URLS.SUBSCRIPTIONS_VALIDATE_PROMO_CODE, { promo_code });
};

export const createPayment = (body: PaymentRequest): AxiosPromise<PaymentResponse> => {
    return globalAgent.post(URLS.SUBSCRIPTIONS_CREATE_PAYMENT, { ...body });
};

export const cancelPayment = (): AxiosPromise<unknown> => {
    return globalAgent.post(URLS.SUBSCRIPTIONS_CANCEL);
};
