const getPeriodsInfo = (price) => {
    return {
        1: {
            title: 'Месяц',
            value: 1,
            price: price * 1,
            sale: 0,
            priceWithSale: price * 1,
        },
        3: {
            title: '3 месяца',
            value: 3,
            price: price * 3,
            sale: 10,
            priceWithSale: ((price * 3) / 100) * 90,
        },
        6: {
            title: '6 месяцев',
            value: 6,
            price: price * 6,
            sale: 20,
            priceWithSale: ((price * 6) / 100) * 80,
        },
        12: {
            title: '12 месяцев',
            value: 12,
            price: price * 12,
            sale: 30,
            priceWithSale: ((price * 12) / 100) * 70,
        },
    };
};

export default getPeriodsInfo;
