import { ReactNode } from 'react';
import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import Trash from 'src/icons/Trash';

interface IListEntity {
    name: string;
    id: string | number;
    subtitle?: string;
}

type ListEntitiesProps = {
    entities: IListEntity[];
    getName?: (entity: IListEntity) => string | ReactNode;
    getKey?: (entity: IListEntity, index: number) => string | number;
    getSecondaryText?: (entity: IListEntity) => string;
    onDeleteItem?: (entity: IListEntity) => void;
};

const ListEntities = ({
    entities,
    getName = (entity) => entity.name,
    getKey = (entity, index) => entity?.id || index,
    getSecondaryText = (entity) => entity.subtitle || '',
    onDeleteItem,
}: ListEntitiesProps) => {
    return (
        <List
            style={{
                border: '1px solid',
                borderColor: '#E3ECF3',
                borderRadius: '16px',
                backgroundColor: '#fff',
                width: '100%',
            }}
            disablePadding
        >
            {entities.map((entity, idx) => (
                <ListItem
                    key={getKey(entity, idx)}
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => onDeleteItem?.(entity)}
                        >
                            <Trash />
                        </IconButton>
                    }
                    divider={entities.length - 1 !== idx}
                >
                    <ListItemText
                        primary={getName(entity)}
                        secondary={getSecondaryText(entity)}
                        secondaryTypographyProps={{
                            color: 'secondary',
                            variant: 'body2',
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default ListEntities;
