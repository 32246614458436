import { create } from 'zustand';

type FilterStore = {
    cities: number[];
    keywords: string[];
    bundles: string[];
    privateChats: number[];
    date: string[];
    setDate: (date: string[]) => void;
    setBundles: (bundles: string[]) => void;
    setCities: (cities: number[]) => void;
    setKeywords: (keywords: string[]) => void;
    setPrivateChats: (chats: number[]) => void;
    clearFilter: () => void;
};

const useFilterStore = create<FilterStore>((set, _get) => ({
    bundles: [],
    setBundles: (bundles) => set({ bundles }),
    cities: [],
    setCities: (cities) => set({ cities }),

    keywords: [],
    setKeywords: (keywords) => set({ keywords }),

    privateChats: [],
    setPrivateChats: (value) => {
        set({ privateChats: value });
    },

    date: [],
    setDate: (date) => set({ date }),

    clearFilter: () => {
        set({
            cities: [],
            keywords: [],
            privateChats: [],
            bundles: [],
            date: [],
        });
    },
}));

export default useFilterStore;
