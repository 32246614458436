import { Box, Typography, Radio } from '@mui/material';
import hexContrastColor from 'hex-contrast-color';
import Checked from 'src/icons/Checked';
import { getWordWithNumber } from 'src/utils';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import formatNumber from 'src/utils/formatNumber';

type SubscriptionItemProps = {
    checkedValue: unknown;
    value: unknown;
    price: number;
    clients: number;
    bundles?: number;
    chats?: number;
    keywords?: number;
    projects?: number;
    color?: string;
    title: string;
};

const SubscriptionItem = ({
    checkedValue,
    value,
    price,
    clients,
    title,
    bundles = 0,
    keywords = 0,
    chats = 0,
    projects = 0,
    color,
}: SubscriptionItemProps) => {
    const checked = value === checkedValue;
    const contrastColor = hexContrastColor(color);
    const isMobile = useIsMobileSize();

    const borderColor = isMobile ? '#E3ECF3' : '#CCC';

    return (
        <Box
            display="block"
            component={'label'}
            border={checked ? '2px solid' : '1px solid'}
            borderColor={checked ? 'primary' : borderColor}
            bgcolor={isMobile ? 'transparent' : 'background.default'}
            borderRadius={4}
            style={{ cursor: 'pointer' }}
            p={2}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
            >
                <Typography
                    variant="h4"
                    color="primary"
                >
                    {title}
                </Typography>
                <Radio value={value} />
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <Typography
                    variant="h2"
                    color="primary"
                >
                    {`~${clients}`}
                </Typography>

                <Typography
                    variant="body2"
                    color="primary"
                >
                    {formatNumber(price)} ₽
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                mb={2}
            >
                <Typography
                    variant="body2"
                    color="secondary"
                >
                    потенциальных клиентов в месяц
                </Typography>
                <Typography
                    variant="body2"
                    color="secondary"
                >
                    в месяц
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                gap="6px"
            >
                {projects > 0 && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={color}
                            fontColor={contrastColor}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(projects, ['Проект', 'Проекта', 'Проектов'])}
                        </Typography>
                    </Box>
                )}
                {bundles > 0 && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={color}
                            fontColor={contrastColor}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(bundles, ['подборка', 'подборки', 'подборок'])}
                        </Typography>
                    </Box>
                )}
                {chats > 0 && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={color}
                            fontColor={contrastColor}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(chats, ['Чат', 'Чата', 'Чатов'])}
                        </Typography>
                    </Box>
                )}
                {keywords > 0 && (
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={color}
                            fontColor={contrastColor}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(keywords, ['ключевое слово', 'ключевых слов', 'ключевых слов'])}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SubscriptionItem;
