import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Button, Dialog, DialogContent, Slide, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import CenterScreen from 'src/components/CenterScreen';
import LOCAL_KEYS from 'src/constants/localKeys';
import useUserStore from 'src/stores/user';
import { WithoutHeader, DesktopLayout } from 'src/layout';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { ROUTES_URL } from 'src/constants/routes';
import useGoTo from 'src/hooks/useGoTo';

const messages = {
    h1: 'Добро пожаловать',
    sub: 'Вова пишет крутое приветсвие новых клиентов',
};

const useStyles = makeStyles(() => createStyles({
    paper: {
        maxWidth: 700,
    }
}));

export default function WelcomeScreen({ referal = false }) {
    const isMobile = useIsMobileSize();
    const { isOnboardingCompleted } = useUserStore(({ isOnboardingCompleted }) => ({ isOnboardingCompleted }));
    const classes = useStyles();
    const [step, setStep] = useState(1);
    const goTo = useGoTo();

    if (referal) {
        messages.h1 = 'Привет вот тебе 500 баллов';
        messages.sub = 'Вова пишет крутое приветсвие новых клиентов';
    }

    const handleClick = () => {
        if (step === 4) {
            goTo(ROUTES_URL.BEGIN_WORK);
            return;
        }

        setStep((old) => (old += 1));
    };

    const renderFirstScreen = () => {
        return (
            <Slide
                in={step === 1}
                direction="right"
                timeout={{
                    enter: 800,
                    exit: 0,
                }}
                mountOnEnter
                unmountOnExit
            >
                <Box height={240}>
                    <Box mb={5}>
                        <Typography
                            textAlign="center"
                            variant="h1"
                            color="primary"
                        >
                            Добро пожаловать
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            textAlign="center"
                            variant="body1"
                            color="primary"
                        >
                            Более <strong>950 миллионов человек</strong> пользуются Telegram по всему миру. С TG Hunter
                            вы легко находите тех, кто заинтересовался продуктом или услугой вашей тематики
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        );
    };

    const renderSecondScreen = () => {
        return (
            <Slide
                in={step === 2}
                direction="right"
                timeout={{
                    enter: 800,
                    exit: 0,
                }}
                mountOnEnter
                unmountOnExit
            >
                <Box>
                    <Box mb={5}>
                        <Typography
                            textAlign="center"
                            variant="h1"
                            color="primary"
                        >
                            Как работает
                            <br /> сервис?
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            textAlign="center"
                            variant="body1"
                            color="primary"
                        >
                            TG Hunter <strong>круглосуточно мониторит</strong> чаты и каналы в Telegram. Как только ваши
                            ключевые слова появляются в сообщениях — вы мгновенно получаете уведомление
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        );
    };

    const renderThirdSceen = () => {
        return (
            <Slide
                in={step === 3}
                direction="right"
                timeout={{
                    enter: 800,
                    exit: 0,
                }}
                mountOnEnter
                unmountOnExit
            >
                <Box>
                    <Box mb={5}>
                        <Typography
                            textAlign="center"
                            variant="h1"
                            color="primary"
                        >
                            Новые лиды
                            <br /> для вашего бизнеса
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            textAlign="center"
                            variant="body1"
                            color="primary"
                        >
                            Вы получаете возможность и преимущество - первым предложить ваш продукт или услугу.
                            Обрабатывайте свежие целевые запросы и <strong>увеличивайте рост продаж</strong>
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        );
    };

    const renderFourthSceen = () => {
        return (
            <Slide
                in={step === 4}
                direction="right"
                timeout={{
                    enter: 800,
                    exit: 0,
                }}
                mountOnEnter
                unmountOnExit
            >
                <Box>
                    <Box mb={5}>
                        <Typography
                            textAlign="center"
                            variant="h1"
                            color="primary"
                        >
                            Начать легко!
                            <br />
                            Дарим 500 баллов
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            textAlign="center"
                            variant="body1"
                            color="primary"
                        >
                            Вы получаете возможность и преимущество - первым предложить ваш продукт или услугу.
                            Обрабатывайте свежие целевые запросы и <strong>увеличивайте рост продаж</strong>
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        );
    };

    const renderButtonNext = () => {
        return (
            <Button
                onClick={handleClick}
                variant="contained"
                size="large"
                fullWidth
            >
                {step < 4 ? 'Далее' : 'Начать'}
            </Button>
        );
    };

    if (isOnboardingCompleted) {
        return <Redirect to="/" />;
    }

    if (!isMobile) {
        return (
            <DesktopLayout>
                <Dialog
                    onClose={() => {}}
                    open={true}
                    classes={{
                        paper: classes.paper
                    }}
                >
                    <DialogContent>
                        <Box display="flex" flexDirection="column" justifyContent="center" height={300}>
                        {renderFirstScreen()}
                        {renderSecondScreen()}
                        {renderThirdSceen()}
                        {renderFourthSceen()}
                        <Box mt={2}>{renderButtonNext()}</Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </DesktopLayout>
        );
    }

    return (
        <WithoutHeader showMyHeader={false}>
            <Box
                bgcolor="background.paper"
                position="absolute"
                top={0}
                left={0}
                width={1}
                height={'100vh'}
            >
                <Box
                    position="relative"
                    display="flex"
                    flexDirection="column"
                    height={'100vh'}
                >
                    <CenterScreen>
                        <Box
                            maxHeight={240}
                            overflow="hidden"
                            px={2}
                        >
                            {renderFirstScreen()}
                            {renderSecondScreen()}
                            {renderThirdSceen()}
                            {renderFourthSceen()}
                        </Box>
                    </CenterScreen>
                    <Box
                        display="flex"
                        alignItems="flex-end"
                        pb={10}
                        flexGrow={3}
                        width={230}
                        mx={'auto'}
                    >
                        {renderButtonNext()}
                    </Box>
                </Box>
            </Box>
        </WithoutHeader>
    );
}
