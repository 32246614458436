export default ({ fillColor = '#1F1F1F', fontColor = 'white' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g>
            <g>
                <rect
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                    rx="11"
                    fill={fillColor}
                />
            </g>
            <path
                d="M8.81373 11.9188C8.49272 11.4693 7.86817 11.3653 7.41876 11.6863C6.96935 12.0073 6.86526 12.6318 7.18627 13.0812L8.81373 11.9188ZM16.8247 9.56553L17.3903 8.7408L15.7408 7.60974L15.1753 8.43447L16.8247 9.56553ZM7.18627 13.0812L9.21296 15.9186L10.8404 14.7561L8.81373 11.9188L7.18627 13.0812ZM12.4899 15.8872L16.8247 9.56553L15.1753 8.43447L10.8404 14.7561L12.4899 15.8872ZM9.21296 15.9186C10.02 17.0485 11.7046 17.0323 12.4899 15.8872L10.8404 14.7561H10.8404L9.21296 15.9186Z"
                fill={fontColor}
            />
        </g>
    </svg>
);
