export const API_URL = process.env.REACT_APP_API_URL || '/api';

export const URLS = {
    SIGN_IN: '/accounts/token/',
    REGISTER: '/accounts/register/',
    REFRESH: '/accounts/token/refresh/',
    CONFIRM_EMAIL: '/accounts/confirm-email/',
    REFETCH_EMAIL_CODE: '/accounts/confirm-email/refetch-code/',
    PASSWORD_RESET: '/accounts/password-reset/',
    UPDATE_ONBOARDING_STEP: '/accounts/update-onboarding-step/',
    PASSWORD_CONFIRM_RESET: '/accounts/password-reset-confirm/',
    CHANGE_EMAIL: '/accounts/change-email/request/',
    CHANGE_EMAIL_CONFIRM: '/accounts/change-email/confirm/',
    USER: '/accounts/me/',
    SUBSCRIPTIONS: '/subscriptions/plans/',
    SUBSCRIPTIONS_CREATE_PAYMENT: '/subscriptions/create-payment/',
    SUBSCRIPTIONS_CANCEL: '/subscriptions/cancel/',
    SUBSCRIPTIONS_VALIDATE_PROMO_CODE: '/subscriptions/validate-promo-code/',
    PROJECTS: '/projects/',
    PROJECTS_CREATE: '/projects/create/',
    CHATS: '/chats/',
    KEYWORDS: '/keywords/',
    BUNDLES: '/chats/bundles/',
    BUNDLES_CREATE: '/chats/bundles/create/',
    MESSAGES: '/messages/',
};
