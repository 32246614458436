import { updateBeginWorkStep } from 'src/providers/UpdateBeginWorkStepsProvider';
import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';

import useGoTo from 'src/hooks/useGoTo';

import ChatsAndChannelsScreen from '../ChatsAndChannels';

export default function BeginWorkChatsAndChannels() {
    const goTo = useGoTo();

    const onSuccessHandler = () => {
        updateBeginWorkStep({
            currentStep: BEGIN_WORK_STEPS_IDS.ADD_CHATS,
            onSuccess: () => {
                goTo('/');
            },
        });
    };

    return <ChatsAndChannelsScreen onSuccess={onSuccessHandler} />;
}
