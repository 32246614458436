import { Box, Collapse, Typography, styled } from '@mui/material';
import ChevroneUp from 'src/icons/ChevroneUp';
import useAppStore from 'src/stores/app';

import ItemMenu from './ItemMenu';

import type { ReactNode } from 'react';

export const AccordeonMenuContainer = styled(Box)(() => ({
    border: '1px solid #E3ECF3',
    borderRadius: '16px',
    padding: '8px',
    opacity: 1,
    '& > div > div > div > div': {
        borderBottom: '1px solid #E3ECF3',
    },
    '& > div > div > div > div:last-child': {
        borderBottom: 'none',
    },
}));

export const AccordeonButton = ({ isOpen = false }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            gap="8px"
            style={{
                cursor: 'pointer',
            }}
            height={32}
        >
            <Typography
                variant="body2"
                color="secondary"
            >
                {isOpen ? 'Скрыть' : 'Показать'}
            </Typography>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={24}
                width={24}
                borderRadius="50%"
                bgcolor={isOpen ? 'background.paper' : 'background.default'}
                style={{
                    transform: !isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transformOrigin: 'center',
                }}
            >
                <ChevroneUp />
            </Box>
        </Box>
    );
};

export type AccordeonMenuItem = {
    title: string;
    icon: ReactNode;
    customActionIcon?: ReactNode;
    onClick: () => void;
};

type AccordeonMenuProps = {
    items: AccordeonMenuItem[];
    isMobile: boolean;
};

const AccordeonMenu = ({ items = [], isMobile }: AccordeonMenuProps) => {
    const { accordeonMenu, setAccordeonMenu } = useAppStore(({ accordeonMenu, setAccordeonMenu }) => ({
        accordeonMenu,
        setAccordeonMenu,
    }));

    const handleToggleMenu = () => {
        setAccordeonMenu(!accordeonMenu);
    };

    const getBackgroundColor = () => {
        if (accordeonMenu && isMobile) {
            return 'background.default';
        }

        if (!accordeonMenu && isMobile) {
            return 'background.paper';
        }

        return 'background.default';
    };

    return (
        <AccordeonMenuContainer bgcolor={getBackgroundColor()}>
            <Collapse
                collapsedSize={'48px'}
                in={isMobile ? accordeonMenu : true}
            >
                {
                    isMobile && (
                        <ItemMenu
                            title="Ещё настройки"
                            onAction={handleToggleMenu}
                            customActionComponent={<AccordeonButton isOpen={accordeonMenu} />}
                        />
                    )
                }
                {items.map((item) => {
                    return (
                        <ItemMenu
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            onAction={item.onClick}
                            customActionComponent={item.customActionIcon}
                        />
                    );
                })}
            </Collapse>
        </AccordeonMenuContainer>
    );
};

export default AccordeonMenu;
