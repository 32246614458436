export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <g id="Icon 16 M">
            <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00001 6C9.00001 7.65685 7.65687 9 6.00001 9C4.34316 9 3.00001 7.65685 3.00001 6C3.00001 4.34315 4.34316 3 6.00001 3C7.65687 3 9.00001 4.34315 9.00001 6ZM9.28642 8.28087C8.56396 9.31988 7.36141 10 6.00001 10C3.79087 10 2.00001 8.20914 2.00001 6C2.00001 3.79086 3.79087 2 6.00001 2C7.83075 2 9.37423 3.22989 9.84928 4.90851C10.3943 4.34811 11.1565 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.8014 10 9.76691 9.29703 9.28642 8.28087ZM9.85087 10.907C9.33662 10.4407 8.68245 10.1255 7.95685 10.0299C7.72897 9.99994 7.46644 9.99996 7.04373 10L7 10H6.00001H5L4.95628 10C4.53357 9.99996 4.27104 9.99994 4.04316 10.0299C2.4726 10.2367 1.23672 11.4726 1.02995 13.0432C0.999947 13.271 0.999968 13.5336 1 13.9563L1 14H2C2 13.5197 2.00108 13.328 2.02139 13.1737C2.16908 12.0519 3.05186 11.1691 4.17369 11.0214C4.32796 11.0011 4.51969 11 5 11H7C7.48032 11 7.67205 11.0011 7.82632 11.0214C8.94815 11.1691 9.83092 12.0519 9.97862 13.1737C9.99893 13.328 10 13.5197 10 14H11L11 13.9686C11.0001 13.9791 11.0001 13.9895 11.0001 14H14.0002H15.0002V13C15.0002 11.3431 13.657 10 12.0002 10C11.1574 10 10.3958 10.3475 9.85087 10.907ZM10.493 11.6852C10.7318 12.0788 10.8954 12.5236 10.9641 13H14.0002C14.0002 11.8954 13.1047 11 12.0002 11C11.3989 11 10.8596 11.2653 10.493 11.6852ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                fill="#1F1F1F"
            />
        </g>
    </svg>
);
