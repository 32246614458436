import { memo } from 'react';
import { Box } from '@mui/material';
import Header from 'src/widgets/Header';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import useStyles from './layout.styles';

import type { ReactNode } from 'react';

type LayoutProps = {
    children?: ReactNode;
    isWhite?: boolean;
};

export default function Layout({ children, isWhite = true }: LayoutProps) {
    const classes = useStyles();
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <Box className={classes.root}>
                <Box
                    className={classes.container}
                    py={{ lg: 0, md: 0, sm: 0.5, xs: 0.5 }}
                    px={{ lg: 0, md: 0, sm: 2, xs: 2 }}
                    mx={{ lg: 'auto', md: 'auto', sm: 0, xs: 0 }}
                    minHeight={'100%'}
                    height="1px"
                >
                    {children}
                </Box>
            </Box>
        );
    }

    return (
        <Box className={classes.root}>
            <Header white={isWhite} />
            <Box
                className={classes.containerLight}
                py={{ lg: 0, md: 0, sm: 0.5, xs: 0.5 }}
                px={{ lg: 0, md: 0, sm: 2, xs: 2 }}
                mx={{ lg: 'auto', md: 'auto', sm: 0, xs: 0 }}
                minHeight={'calc(100dvh - 48px)'}
                height="1px"
            >
                {children}
            </Box>
        </Box>
    );
}
