import { updateBeginWorkStep } from 'src/providers/UpdateBeginWorkStepsProvider';
import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';

import useGoTo from 'src/hooks/useGoTo';

import Collection from '../Collections';

export default function BeginWorkCollections() {
    const goTo = useGoTo();

    const onSuccessHandler = () => {
        updateBeginWorkStep({
            currentStep: BEGIN_WORK_STEPS_IDS.CHOOSE_COLLECTIONS,
            onSuccess: () => {
                goTo('/');
            },
        });
    };

    return <Collection onSuccess={onSuccessHandler} />;
}
