import { Box, Typography, FormControl, RadioGroup, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import formatNumber from 'src/utils/formatNumber';

type PeriodItem = {
    title: string;
    value: number;
    price: number;
    sale: number;
    priceWithSale?: number;
};

type PeriodFieldProps = {
    control: any;
    name: string;
    periods?: PeriodItem[];
};

const PeriodField = ({ periods = [], control, name }: PeriodFieldProps) => {
    const isMobile = useIsMobileSize();
    const borderColor = isMobile ? '#E3ECF3' : '#CCCCCC';
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl
                    style={{
                        border: '1px solid',
                        borderColor: borderColor,
                        borderRadius: '16px',
                        backgroundColor: '#fff',
                        width: '100%',
                    }}
                >
                    <RadioGroup
                        {...field}
                        onChange={(event) => {
                            field.onChange(+event.target.value);
                        }}
                    >
                        {periods.map((period, idx) => (
                            <Box
                                key={period.value}
                                display="block"
                                component={'label'}
                                borderBottom={idx === periods.length - 1 ? 'none' : `1px solid ${borderColor}`}
                                p={1}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="body1"
                                        color="primary"
                                    >
                                        {period.title}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="6px"
                                    >
                                        {period.sale > 0 && (
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    component="del"
                                                    color="secondary"
                                                >
                                                    {formatNumber(period.price)} ₽
                                                </Typography>
                                                <Box
                                                    height={16}
                                                    borderRadius={3}
                                                    display="flex"
                                                    alignItems="center"
                                                    px={0.5}
                                                    py={1}
                                                    bgcolor="warning.main"
                                                    style={{
                                                        border: '1px solid rgba(228, 142, 63, 0.20)',
                                                        boxShadow: '0px 0px 8px 0px rgba(250, 255, 0, 0.60)',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="overline"
                                                        color="primary"
                                                    >
                                                        {period.sale}%
                                                    </Typography>
                                                </Box>
                                            </>
                                        )}
                                        <Box mr={-1}>
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                            >
                                                {formatNumber(period.priceWithSale)} ₽
                                            </Typography>
                                        </Box>
                                        <Radio value={period.value} />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
        />
    );
};

export default PeriodField;
