export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g>
            <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6074 5.09961C15.069 3.63802 17.4387 3.63802 18.9003 5.09961C20.3619 6.5612 20.3619 8.93091 18.9003 10.3925L16.1464 13.1464C15.9511 13.3417 15.9511 13.6583 16.1464 13.8535C16.3417 14.0488 16.6583 14.0488 16.8535 13.8535L19.6074 11.0996C21.4595 9.2475 21.4595 6.24462 19.6074 4.39251C17.7553 2.54039 14.7524 2.54039 12.9003 4.3925L10.1464 7.14641C9.95115 7.34167 9.95115 7.65826 10.1464 7.85352C10.3417 8.04878 10.6583 8.04878 10.8535 7.85352L13.6074 5.09961ZM14.8536 9.14645C15.0488 9.34171 15.0488 9.65829 14.8536 9.85355L9.85355 14.8536C9.65829 15.0488 9.34171 15.0488 9.14645 14.8536C8.95118 14.6583 8.95118 14.3417 9.14645 14.1464L14.1464 9.14645C14.3417 8.95118 14.6583 8.95118 14.8536 9.14645ZM7.85352 10.1464C8.04878 10.3417 8.04878 10.6583 7.85352 10.8535L5.0918 13.6152C3.63021 15.0768 3.63021 17.4465 5.0918 18.9081C6.55339 20.3697 8.9231 20.3697 10.3847 18.9081L13.1464 16.1464C13.3417 15.9511 13.6583 15.9511 13.8535 16.1464C14.0488 16.3417 14.0488 16.6583 13.8535 16.8535L11.0918 19.6152C9.23968 21.4674 6.23681 21.4674 4.38469 19.6152C2.53258 17.7631 2.53258 14.7602 4.38469 12.9081L7.14641 10.1464C7.34167 9.95115 7.65826 9.95115 7.85352 10.1464Z"
                fill="#1F1F1F"
            />
        </g>
    </svg>
);
