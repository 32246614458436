export default () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_2065_15096)">
                <path
                    d="M2.5 12C2.5 10.5916 2.50039 9.5654 2.56646 8.75673C2.63205 7.95389 2.75989 7.40959 2.99047 6.95704C3.4219 6.11031 4.11031 5.4219 4.95704 4.99047C5.40959 4.75989 5.95389 4.63205 6.75673 4.56646C7.5654 4.50039 8.59162 4.5 10 4.5H14C15.4084 4.5 16.4346 4.50039 17.2433 4.56646C18.0461 4.63205 18.5904 4.75989 19.043 4.99047C19.8897 5.4219 20.5781 6.11031 21.0095 6.95704C21.2401 7.40959 21.3679 7.95389 21.4335 8.75673C21.4996 9.5654 21.5 10.5916 21.5 12C21.5 13.4084 21.4996 14.4346 21.4335 15.2433C21.3679 16.0461 21.2401 16.5904 21.0095 17.043C20.5781 17.8897 19.8897 18.5781 19.043 19.0095C18.5904 19.2401 18.0461 19.3679 17.2433 19.4335C16.4346 19.4996 15.4084 19.5 14 19.5H10C8.59162 19.5 7.5654 19.4996 6.75673 19.4335C5.95389 19.3679 5.40959 19.2401 4.95704 19.0095C4.11031 18.5781 3.4219 17.8897 2.99047 17.043C2.75989 16.5904 2.63205 16.0461 2.56646 15.2433C2.50039 14.4346 2.5 13.4084 2.5 12Z"
                    stroke="black"
                />
                <g clipPath="url(#clip1_2065_15096)">
                    <path
                        d="M11.6181 13.4506L11.7454 12.9671L11.6181 13.4506C11.8685 13.5165 12.1315 13.5165 12.3819 13.4506L12.2546 12.9671L12.3819 13.4506C12.6682 13.3752 12.9166 13.1884 13.1997 12.9753C13.2196 12.9603 13.2397 12.9452 13.26 12.93L23.5 5.25V21.5H0.5V5.25L10.74 12.93C10.7603 12.9452 10.7804 12.9603 10.8003 12.9753C11.0834 13.1884 11.3318 13.3752 11.6181 13.4506Z"
                        stroke="black"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2065_15096">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="clip1_2065_15096">
                    <rect
                        x="2"
                        y="6"
                        width="20"
                        height="14"
                        rx="3"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
