import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import CollectionService from 'src/services/CollectionService';

import useProjectsStore from './projects';

import type {
    TCollections,
    ICollectionsChatBundles,
    ICollectionsChatBundleRequest,
    ICollectionsChatBundle,
} from 'src/types/Collections';

interface IState {
    publicCollections: ICollectionsChatBundles;

    privateCollection: ICollectionsChatBundle;

    cities: string[];
}

interface IActions {
    setCollections: (collections: TCollections) => void;

    getCollections: (city?: string, tags?: string[]) => Promise<void>;

    updateCollection: (collection: ICollectionsChatBundleRequest) => Promise<void>;

    getPrivateCollection: () => Promise<ICollectionsChatBundle>;
    clear: () => void;
}

const useCollectionsStore = create<IState & IActions>(
    (set, get) => ({
        publicCollections: [],

        privateCollection: {} as ICollectionsChatBundle,

        cities: [],
        clear: () => set({
            publicCollections: [],
            privateCollection: {} as ICollectionsChatBundle,
            cities: [],
        }),
        setCollections: ({ chat_bundles, cities }) => {
            const { publicCollections, privateCollections } = chat_bundles.reduce(
                (acc, item) => {
                    if (item.is_public) {
                        acc.publicCollections.push(item);
                    } else {
                        acc.privateCollections.push(item);
                    }
                    return acc;
                },
                {
                    publicCollections: [] as ICollectionsChatBundles,
                    privateCollections: [] as ICollectionsChatBundles,
                }
            );

            set({ publicCollections, privateCollection: privateCollections[0], cities });
        },

        getCollections: async (city, tags) => {
            try {
                const data = await CollectionService.getAllCollections(city, tags);
                get().setCollections(data);
                return Promise.resolve();
            } catch (err) {
                return Promise.reject(err);
            }
        },

        getPrivateCollection: async () => {
            try {
                const activeProjectId = useProjectsStore.getState().activeProject?.id;

                if (!activeProjectId) {
                    throw new Error('Нет активного проекта');
                }

                const data = await CollectionService.getPrivateCollection(activeProjectId);

                if (!data.chat_bundles[0]) {
                    throw new Error('Личная подборка не найдена!');
                }
                set({
                    privateCollection: data.chat_bundles[0],
                });
                return Promise.resolve(get().privateCollection);
            } catch (err) {
                return Promise.reject(err);
            }
        },

        updateCollection: async (collection: ICollectionsChatBundleRequest) => {
            try {
                const data = await CollectionService.updateCollection(collection);

                set({
                    privateCollection: data,
                });

                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },
    }),
    shallow
);

export default useCollectionsStore;
