import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import Snackbar from 'src/components/Snackbar';

interface ISnackbarProvider {
    children: React.ReactNode;
}

export default function SnackbarProvider({ children }: ISnackbarProvider) {
    return (
        <NotistackSnackbarProvider
            Components={{
                default: Snackbar,
                error: Snackbar,
                warning: Snackbar,
                success: Snackbar,
                info: Snackbar,
            }}
            maxSnack={5}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {children}
        </NotistackSnackbarProvider>
    );
}
