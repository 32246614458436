import { Box, BoxProps } from '@mui/material';

import Loader from './Loader';

type ICommonLoadingWrapeprProps<T extends boolean | undefined> = {
    isLoading: boolean;
    children: React.ReactNode;
    /**
     * @description Используется для отображение загрузки в конкретном компоненте
     * Компонент становится "absolute" и растягивается по родителю, который добавляется автоматически (MuiBox)
     */
    isLocal?: T;
    /**
     * @description Используется для кастомизации обертки над дочерними компонентами
     * Важно! Лоадер имеет position "absolute" и растягивается по данному компоненту
     */
    boxWrapperProps?: T extends true ? BoxProps : never;

    isForced?: boolean;
};

export default function LoadingWrapper<T>({
    isLoading,
    children,
    isLocal,
    boxWrapperProps,
    isForced,
}: ICommonLoadingWrapeprProps<T extends boolean ? T : undefined>) {
    if (isForced && isLoading) {
        return (
            <Loader
                isOpen={true}
                isLocal={isLocal}
            />
        );
    }
    if (isLocal) {
        return (
            <Box
                position="relative"
                {...boxWrapperProps}
            >
                {children}
                {
                    <Loader
                        isOpen={isLoading}
                        isLocal={isLocal}
                    />
                }
            </Box>
        );
    }

    return (
        <>
            {children}
            {
                <Loader
                    isOpen={isLoading}
                    isLocal={isLocal}
                />
            }
        </>
    );
}
