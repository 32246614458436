// import { ICollectionsChatBundles } from 'src/types/Collections';
import useFilterStore from 'src/stores/filter';

import { ISecureChatInfo } from 'src/types/Collections';
import { IKeyword } from 'src/types/Keywords';
import { IProjectBundle } from 'src/types/Projects';

export interface IFilterSchemeItem {
    value: string | number;
    label: string;
}

type IFilterSchemeItems = IFilterSchemeItem[];
export interface IFilterOption {
    defaultValues: Obj;
    type: 'radio' | 'checkbox';
    id: number | string;
    subTitle?: string;
    selectAllTitle?: string;
    items: IFilterSchemeItems;
    maxItems: number | null;
    withClearButton: boolean;
    withSubmitButton: boolean;
}
export interface IFilterScheme {
    title: string;
    withExpanded?: boolean;
    options: IFilterOption[];
    defaultValues?: Obj;
    onSubmit: (data: any) => any;
}

export const FILTER_IDS = {
    MESSAGE_DATE: 'message_date',
    KEYWORDS: 'keywords',
    CHATS_AND_CHANNELS_PRIVATE: 'chats_and_channels_private',
    BUNDLES: 'bundles',
} as const;

export const FILTER_IDS_MESSAGE_DATE = {
    ALL: 'all',
    WEEK: 'week',
    MONTH: 'month',
};

interface IGetFiltersParams {
    bundles: IProjectBundle[],
    chats: ISecureChatInfo[];
    keywords: IKeyword[];
}

export const getChatsFilter = (chats: ISecureChatInfo[], isMobile?: boolean): IFilterScheme => ({
    title: 'Чаты и каналы',
    withExpanded: !isMobile,
    options: [
        {
            defaultValues: { [FILTER_IDS.CHATS_AND_CHANNELS_PRIVATE]: useFilterStore.getState().privateChats },
            type: 'checkbox',
            id: FILTER_IDS.CHATS_AND_CHANNELS_PRIVATE,
            items: chats.map(({ id, name, telegram_link }) => ({
                value: String(id),
                label: String(name || telegram_link),
            })),
            selectAllTitle: 'Выбрать все',
            maxItems: isMobile ? null : 3,
            withSubmitButton: !!isMobile,
            withClearButton: !!isMobile,
        },
    ],
    onSubmit: (data: Obj) => {
        useFilterStore.getState().setPrivateChats(data[FILTER_IDS.CHATS_AND_CHANNELS_PRIVATE]);
    },
});


export const getDateFilter = (isMobile?: boolean): IFilterScheme => ({
    title: 'Дата сообщения',
    withExpanded: !isMobile,
    options: [
        {
            defaultValues: {
                [FILTER_IDS.MESSAGE_DATE]: useFilterStore.getState().date.length
                    ? useFilterStore.getState().date
                    : [FILTER_IDS_MESSAGE_DATE.ALL],
            },
            type: 'radio',
            id: FILTER_IDS.MESSAGE_DATE,
            items: [
                {
                    value: FILTER_IDS_MESSAGE_DATE.ALL,
                    label: 'Все',
                },
                {
                    value: FILTER_IDS_MESSAGE_DATE.WEEK,
                    label: 'Эта неделя',
                },
                {
                    value: FILTER_IDS_MESSAGE_DATE.MONTH,
                    label: 'Этот месяц',
                },
            ],
            withSubmitButton: !!isMobile,
            withClearButton: false,
            maxItems: 3,
        },
    ],
    onSubmit: (data: Obj) => {
        useFilterStore.getState().setDate(data[FILTER_IDS.MESSAGE_DATE]);
    },
});

export const getKeywordsFilter = (keywords: IKeyword[], isMobile?: boolean): IFilterScheme => ({
    title: 'Ключевые слова',
    withExpanded: !isMobile,
    options: [
        {
            defaultValues: { [FILTER_IDS.KEYWORDS]: useFilterStore.getState().keywords },
            type: 'checkbox',
            id: FILTER_IDS.KEYWORDS,
            items: keywords.map(({ word }) => ({
                value: word,
                label: word,
            })),
            selectAllTitle: 'Выбрать все',
            maxItems: isMobile ? null : 3,
            withSubmitButton: !!isMobile,
            withClearButton: !!isMobile,
        },
    ],
    onSubmit: (data) => {
        useFilterStore.getState().setKeywords(data[FILTER_IDS.KEYWORDS]);
    },
});

export const getBundlesFilter = (bundles: IProjectBundle[], isMobile?: boolean): IFilterScheme => ({
    title: 'Подборки',
    withExpanded: !isMobile,
    options: [
        {
            defaultValues: { [FILTER_IDS.BUNDLES]: useFilterStore.getState().bundles },
            type: 'checkbox',
            id: FILTER_IDS.BUNDLES,
            items: bundles.map(({ id, name }) => ({
                value: String(id),
                label: name || 'Подборка без имени',
            })),
            selectAllTitle: 'Выбрать все',
            maxItems: isMobile ? null : 3,
            withSubmitButton: !!isMobile,
            withClearButton: !!isMobile,
        },
    ],
    onSubmit: (data) => {
        useFilterStore.getState().setBundles(data[FILTER_IDS.BUNDLES]);
    },
});

export const getFilters = ({ chats, keywords, bundles }: IGetFiltersParams): IFilterScheme[] => [
    getDateFilter(),
    getBundlesFilter(bundles),
    getKeywordsFilter(keywords),
    getChatsFilter(chats),

];
