import { useState } from 'react';
import { Box } from '@mui/material';
import ButtonText from 'src/components/ButtonText';
import Timer from 'src/components/Timer';

interface ISendCodeAgainProps {
    timer: number;
    onClick?: () => void | Promise<void>;
    disabled?: boolean;
    subject?: string;
}

export default function SendCodeAgain({ timer = 0, onClick, disabled = false, subject = '' }: ISendCodeAgainProps) {
    const [showTimer, setShowTimer] = useState(false);

    const handleClick = async () => {
        if (!showTimer) {
            try {
                await onClick?.();
                setShowTimer(true);
            } catch (_error) {}
        }
    };

    const timerCallback = () => {
        setShowTimer(false);
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <ButtonText
                icon="RELOAD"
                onClick={handleClick}
                disabled={showTimer || disabled}
            >
                Отправить код снова
            </ButtonText>
            {showTimer && (
                <Timer
                    timeout={timer}
                    callback={timerCallback}
                />
            )}
        </Box>
    );
}
