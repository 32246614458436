import { getMessages, toggleFavorite, removeMessage } from './agent';

import type { IMessagesRequest, IMessagesResponse } from 'src/types/Message';

class MessagesService {
    fetchMessages = async (params: IMessagesRequest): Promise<IMessagesResponse> => {
        try {
            const { data } = await getMessages(params);

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    toggleFavoriteMessage = async (id: number, flag: boolean) => {
        try {
            const { status } = await toggleFavorite(id, flag);

            if (status === 200) {
                return Promise.resolve({});
            }

            throw new Error(`Неудалось ${flag ? 'добавить в избранное' : 'удалить из избранного'}`);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    removeMessage = async (id: number) => {
        try {
            const { status } = await removeMessage(id);

            if (status === 200) {
                return Promise.resolve({});
            }

            throw new Error('Сообщение не найдено или вы не имеете прав на его удаление.');
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new MessagesService();
