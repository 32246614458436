import {
    Box,
    Button,
    // Typography,
    Link,
} from '@mui/material';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import LoginField from 'src/components/LoginField';
import PasswordField from 'src/components/PasswordField';
import WidgetWrapper from 'src/components/FormWrapper';
// import TelegramButton from 'src/widgets/TelegramButton'
import ButtonText from 'src/components/ButtonText';
import useGoTo from 'src/hooks/useGoTo';
import ROUTES from 'src/router/routes';

import scheme, { type TypeLoginScheme } from 'src/validations/login';
import useSessionStore from 'src/stores/session';
import useRestorePassword from 'src/stores/restorePassword';

import { enqueueSnackbar } from 'notistack';

import { useMutation } from 'react-query';
import SessionService from 'src/services/SessionService';

import LoadingWrapper from 'src/components/Loading/LoadingWrapper';

import type { SubmitHandler } from 'react-hook-form';

export default function LoginForm() {
    const goTo = useGoTo();

    const isAuthorized = useSessionStore(({ isAuthorized }) => isAuthorized);

    const passwordRestoreStore = useRestorePassword(({ setEmail }) => ({ setEmail }));

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        setFocus,
        getValues,
    } = useForm<TypeLoginScheme>({
        resolver: zodResolver(scheme),
    });

    const { mutate: login, isLoading: isLoginLoading } = useMutation(
        SessionService.queryKey,
        useSessionStore.getState().login,
        {
            onSuccess: () => {
                goTo('/');
            },
            onError: (error: Obj) => {
                enqueueSnackbar({
                    message: error?.detail,
                    variant: 'error',
                });
            },
        }
    );

    if (isAuthorized) return <Redirect to={'/'} />;

    const restorePasswordhandleClick = () => {
        const { email } = getValues();
        if (email) {
            passwordRestoreStore.setEmail(email);
            goTo(ROUTES.RESTORE_PASSWORD.path);
        } else {
            setFocus('email');
            setError('email', { message: 'Введите email для сброса пароля' });
        }
    };

    const onSubmit: SubmitHandler<TypeLoginScheme> = (data) => {
        login(data);
    };

    return (
        <LoadingWrapper isLoading={isLoginLoading}>
            <WidgetWrapper
                name="Вход"
                topGutter
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mb={2}>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <LoginField
                                    {...field}
                                    label="Почта"
                                    placeholder="user@sample.com"
                                    error={errors?.email?.message}
                                />
                            )}
                        />
                    </Box>
                    <Box mb={2}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <PasswordField
                                    {...field}
                                    label="Пароль"
                                    error={errors?.password?.message}
                                />
                            )}
                        />
                    </Box>
                    <Box mb={2}>
                        <Button
                            disabled={isLoginLoading}
                            type="submit"
                            variant="contained"
                            size="large"
                            fullWidth
                        >
                            Войти
                        </Button>
                    </Box>
                    {/* <Box mb={2}>
                    <Typography variant="body2" color="secondary" textAlign="center">или</Typography>
                </Box>
                <Box mb={2} display="flex" justifyContent="center">
                    <TelegramButton />
                </Box> */}
                </form>
            </WidgetWrapper>
            <Box
                mt={1}
                mb={0.5}
                display="flex"
                justifyContent="center"
            >
                <ButtonText
                    icon="INFO"
                    onClick={restorePasswordhandleClick}
                >
                    Восстановить пароль
                </ButtonText>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
            >
                <Link
                    textAlign="center"
                    variant="caption"
                    color="primary"
                    component={RouterLink}
                    to={ROUTES.REGISTER.path}
                >
                    Нет аккаунта? Создать аккаунт
                </Link>
            </Box>
        </LoadingWrapper>
    );
}
