import { Typography } from '@mui/material';
import React from 'react';

interface ITitleProps {
    children: React.ReactNode;
}

export default function Title({ children }: ITitleProps) {
    return (
        <Typography
            variant="h2"
            color="primary"
        >
            {children}
        </Typography>
    );
}
