import { forwardRef } from 'react';
import { Box, type BoxProps, CircularProgress, Modal as MuiModal, styled } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

interface ILoaderProps extends BoxProps {
    isOpen: boolean;
    /**
     * @description Используется для отображение загрузки в конкретном компоненте
     * Родитель компонента должен быть position: 'relative'
     */
    isLocal?: boolean;
}

const backdropStyles = {
    bgcolor: 'background.default',
    style: {
        opacity: 0.7,
        zIndex: zIndex.modal,
    },
};

const Modal = styled(MuiModal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Wrapper = forwardRef((props: BoxProps, ref) => (
    <Box
        ref={ref}
        left={0}
        right={0}
        top={0}
        bottom={0}
        {...props}
    />
));

export default function Loader({ isOpen, isLocal, ...props }: ILoaderProps) {
    if (isLocal && isOpen) {
        return (
            <Wrapper
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                {...backdropStyles}
                {...props}
            >
                <CircularProgress color="primary" />
            </Wrapper>
        );
    }
    return (
        <Modal
            open={isOpen}
            slots={{
                backdrop: () => (
                    <Wrapper
                        position={'fixed'}
                        {...backdropStyles}
                    />
                ),
            }}
        >
            <Wrapper
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
            >
                <CircularProgress color="primary" />
            </Wrapper>
        </Modal>
    );
}
