import { object, string, TypeOf, any } from 'zod';

const ChangeEmail = object({
    new_email: string().min(1, 'Email обязательное поле').email('Поле Email не валидно'),
    code: any(),
});

export type TypeChangeEmailScheme = TypeOf<typeof ChangeEmail>;

export default ChangeEmail;
