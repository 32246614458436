import { Box, IconButton } from '@mui/material';
import EventService from 'src/services/EventService';
import Logo from 'src/components/Logo';
import Separete from 'src/icons/Separete';
import Bookmark from 'src/icons/Bookmark';
import useSessionStore from 'src/stores/session';
import { history } from 'src/utils/history';
import { ROUTES_URL } from 'src/constants/routes';
import zIndex from '@mui/material/styles/zIndex';

export default function Header({ white = false }) {
    const isAuthorized = useSessionStore(({ isAuthorized }) => isAuthorized);

    const handleOpenMenu = () => {
        EventService.dispatch('OPEN_MENU');
    };

    const handleOpenFavorite = () => {
        history.push(ROUTES_URL.FAVORITES);
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor={white ? 'background.default' : 'background.paper'}
            position="sticky"
            height={48}
            zIndex={zIndex.appBar}
            px={1.5}
            py={0.5}
            top={0}
        >
            <Logo />
            <Box>
                {isAuthorized && (
                    <IconButton onClick={handleOpenFavorite}>
                        <Bookmark />
                    </IconButton>
                )}
                {isAuthorized && (
                    <IconButton
                        onClick={handleOpenMenu}
                        sx={{ paddingRight: 0 }}
                    >
                        <Separete />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
}
