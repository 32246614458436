import { useId, type ReactNode } from 'react';
import { Box, Chip } from '@mui/material';
import hexContrastColor from 'hex-contrast-color';

import CustomStepperItem from 'src/components/CustomStepperItem';
import useUserStore from 'src/stores/user';
import useGoTo from 'src/hooks/useGoTo';
import { BEGIN_WORK_STEPS_IDS, FILTERED_BEGIN_WORK_STEPS_ITEM_TYPE } from 'src/constants/beginWorksSteps';

import { getBeginWorkStepByOrder } from 'src/utils/beginWork';

import ApproveEmail from './ApproveEmail';

import type { CustomStepperItemProps } from 'src/components/CustomStepperItem';
import type { StepsItemType, StepsType } from 'src/types/User';

interface IGetChildrenParams {
    done: boolean;
}

interface IStepItem extends Omit<CustomStepperItemProps, 'currentStep' | 'step'> {
    getChildren?: (params: IGetChildrenParams) => React.ReactNode;
    getTitle?: (email: string) => string;
    link?: string;
    id: StepsType;
}

const STEPS_CONTENT: Record<StepsItemType, Omit<IStepItem, 'link' | 'id'>> = {
    [BEGIN_WORK_STEPS_IDS.EMAIL_CONFIRM]: {
        getTitle: (email) => `Подтвердите почту, код отправлен ${email}`,
        titleDone: 'Почта подтверждена',
        getChildren: ({ done }) => (done ? null : <ApproveEmail />),
        isExpanded: true,
    },
    [BEGIN_WORK_STEPS_IDS.CHOOSE_PLAN]: {
        title: 'Выбор тарифа',
    },
    [BEGIN_WORK_STEPS_IDS.CHOOSE_COLLECTIONS]: {
        title: 'Подборки',
    },
    [BEGIN_WORK_STEPS_IDS.ADD_CHATS]: {
        title: 'Чаты и каналы',
    },
    [BEGIN_WORK_STEPS_IDS.ADD_KEYWORDS]: {
        title: 'Ключевые слова',
    },
};

const STEPS: IStepItem[] = FILTERED_BEGIN_WORK_STEPS_ITEM_TYPE.map((step) => ({ ...step, ...STEPS_CONTENT[step.id] }));

export default function Steps() {
    const { onboardingStep, current_subscription, email } = useUserStore(
        ({ onboardingStep, current_subscription, email }) => ({
            onboardingStep,
            current_subscription,
            email,
        })
    );

    const currentStep = onboardingStep || 1;

    const currentStepName = getBeginWorkStepByOrder(currentStep);

    const randomId = useId();
    const goTo = useGoTo();

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="16px"
        >
            {STEPS.map(({ getChildren, isExpanded, id, link, getTitle, title, ...props }, index) => {
                const itemStep = index + 1;
                const done = currentStep > itemStep;
                let chip: ReactNode = null;

                if (id === BEGIN_WORK_STEPS_IDS.CHOOSE_PLAN && current_subscription) {
                    chip = (
                        <Chip
                            label={current_subscription.plan.name}
                            size="small"
                            style={{
                                backgroundColor: '#' + current_subscription.plan.color_code,
                                color: hexContrastColor('#' + current_subscription.plan.color_code),
                            }}
                        />
                    );
                }

                return (
                    <CustomStepperItem
                        key={`${randomId}_${title}`}
                        step={itemStep}
                        currentStep={currentStep}
                        done={currentStep > itemStep}
                        isExpanded={done ? false : isExpanded}
                        onClick={link ? () => goTo(link) : undefined}
                        chip={chip}
                        title={getTitle?.(email) || title}
                        {...props}
                    >
                        {getChildren?.({ done })}
                    </CustomStepperItem>
                );
            })}

            {currentStepName?.id === 'create_project' && (
                <CustomStepperItem
                    step={currentStepName.order}
                    currentStep={currentStep}
                    done={false}
                    onClick={() => goTo(currentStepName.link as string)}
                    title={'Создание проекта'}
                />
            )}
        </Box>
    );
}
