import { getSubscriptions, checkPromocode, createPayment, cancelPayment } from './agent';

import type { AxiosError } from 'axios';

class SubscriptionsService {
    queryKey = 'subscriptions';

    getSubscriptionsList = async () => {
        try {
            const { data } = await getSubscriptions();

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    checkPromocode = async (code: string) => {
        try {
            const { data } = await checkPromocode(code);

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject((error as AxiosError).response?.data);
        }
    };

    createPayment = async (body) => {
        try {
            const { data } = await createPayment(body);

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    cancelSubscription = async () => {
        try {
            await cancelPayment();

            return Promise.resolve({});
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new SubscriptionsService();
