import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { DesktopLayout, WithoutHeader } from 'src/layout';

import Keywords, { IKeywordsProps } from './Content';

export default function KeywordsScreen(props: IKeywordsProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <WithoutHeader showBack>
                <Keywords {...props} />
            </WithoutHeader>
        );
    }

    return (
        <DesktopLayout>
            <Keywords {...props} />
        </DesktopLayout>
    );
}
