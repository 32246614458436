export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <g>
            <rect
                x="2.5"
                y="13.5"
                width="8"
                height="8"
                rx="2.5"
                stroke="black"
            />
            <rect
                x="13.5"
                y="13.5"
                width="8"
                height="8"
                rx="2.5"
                stroke="black"
            />
            <rect
                x="13.5"
                y="2.5"
                width="8"
                height="8"
                rx="2.5"
                stroke="black"
            />
            <rect
                x="2.5"
                y="2.5"
                width="8"
                height="8"
                rx="2.5"
                stroke="black"
            />
        </g>
    </svg>
);
