import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';
import useUserStore from 'src/stores/user';

import FinishCreateProject from '../CreateProject/FinishCreateProject';

export default function BeginWorkFinishProject() {
    const onSuccessHandler = () => {
        useUserStore.getState().setOnboardingInfo(BEGIN_WORK_STEPS_IDS.COMPLETED, true);
    };

    return <FinishCreateProject onSuccess={onSuccessHandler} />;
}
