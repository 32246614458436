import { Box, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ArrowRight from 'src/icons/ArrowRight';
import Checked from 'src/icons/Checked';
import hexContrastColor from 'hex-contrast-color';
import { getWordWithNumber } from 'src/utils';
import useSubscriptionsStore from 'src/stores/subscriptions';
import ROUTES from 'src/router/routes';

import type { Subscription } from 'src/types/Subscription';
import formatNumber from 'src/utils/formatNumber';

type Props = {
    subscription: Subscription;
};

const Tariff = ({ subscription }: Props) => {
    const history = useHistory();
    const { setSelectedPlan } = useSubscriptionsStore(({ setSelectedPlan }) => ({ setSelectedPlan }));

    const handleClick = () => {
        setSelectedPlan(subscription.id);

        history.push(ROUTES.PAYMENT.path);
    };

    return (
        <Box
            display="block"
            component={'label'}
            border={'1px solid'}
            borderColor={'#E3ECF3'}
            bgcolor="background.default"
            borderRadius={4}
            p={1.5}
        >
            <Box mb={0.5}>
                <Typography variant="h4">{subscription.name}</Typography>
            </Box>
            <Box mb={1}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Typography variant="h2">~{subscription.potential_clients_per_month}</Typography>
                    <Typography
                        variant="body2"
                        textAlign="right"
                    >
                        {formatNumber(subscription.price)} ₽
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Typography
                        variant="body2"
                        color="secondary"
                    >
                        потенциальных клиентов в месяц
                    </Typography>
                    <Typography
                        variant="body2"
                        color="secondary"
                        textAlign="right"
                    >
                        в месяц
                    </Typography>
                </Box>
            </Box>
            <Box mb={2}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="6px"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={'#' + subscription.color_code}
                            fontColor={hexContrastColor('#' + subscription.color_code)}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(subscription.bundles_limit, ['подборка', 'подборки', 'подборок'])}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={'#' + subscription.color_code}
                            fontColor={hexContrastColor('#' + subscription.color_code)}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(subscription.chats_limit, ['чат', 'чата', 'чатов'])}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="6px"
                    >
                        <Checked
                            fillColor={'#' + subscription.color_code}
                            fontColor={hexContrastColor('#' + subscription.color_code)}
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            {getWordWithNumber(subscription.keywords_limit, [
                                'ключевое слово',
                                'ключевых слова',
                                'ключевых слов',
                            ])}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Button
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    endIcon={<ArrowRight />}
                >
                    Перейти
                </Button>
            </Box>
        </Box>
    );
};

export default Tariff;
