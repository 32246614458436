import { Box, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

import { ROUTES_URL } from 'src/constants/routes';
import { WithoutHeader, DesktopLayout } from 'src/layout';
import useUserStore from 'src/stores/user';
import SubscriptionsForm from 'src/widgets/SubscriptionsForm';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

export default function SubscriptionsScene() {
    const current_subscription = useUserStore(({ current_subscription }) => current_subscription);
    const isMobile = useIsMobileSize();

    if (current_subscription) {
        return <Redirect to={ROUTES_URL.MY_TARIFF} />;
    }

    const renderContent = () => {
        return (
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100%"
                flexGrow={1}
            >
                <Box mb={3}>
                    <Typography
                        variant="h1"
                        color="primary"
                    >
                        Выбор тарифа
                    </Typography>
                </Box>
                <SubscriptionsForm />
            </Box>
        );
    };

    if (!isMobile) {
        return <DesktopLayout>{renderContent()}</DesktopLayout>;
    }

    return <WithoutHeader showBack>{renderContent()}</WithoutHeader>;
}
