import { forwardRef } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { matchIsNumeric } from 'src/utils';

type OtpFieldProps = {
    error?: string;
    value: string;
    name: string;
    onComplete: () => void;
    onChange: (value: string) => void;
};

const OtpField = forwardRef(({ name, value, onComplete, onChange, error = '', ...otherProps }: OtpFieldProps, ref) => {
    const handleChange = (newValue: string) => {
        onChange(newValue);
    };

    return (
        <Box>
            <MuiOtpInput
                maxWidth={320}
                ref={ref}
                // maxWidth={270}
                gap={'8px'}
                mb={1}
                length={6}
                value={value}
                validateChar={matchIsNumeric}
                onChange={handleChange}
                onComplete={onComplete}
                TextFieldsProps={{
                    error: !!error,
                    name: name,
                    ...otherProps,
                }}
            />
            {!!error && (
                <Box ml={1}>
                    <FormHelperText error>{error}</FormHelperText>
                </Box>
            )}
        </Box>
    );
});

export default OtpField;
