import { type ReactNode } from 'react';
import { useErrorBoundary } from 'react-use-error-boundary';

type ErrorBoundaryProps = {
    children: ReactNode;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    const [error, resetError] = useErrorBoundary();

    if (error) {
        return <>{error}</>;
    }

    return <>{children}</>;
};

export default ErrorBoundary;
