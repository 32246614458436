import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { DesktopLayout, WithoutHeader } from 'src/layout';

import ChatsAndChannels, { IChatsAndChannelsScreenProps } from './Content';

export default function ChatsAndChannelsScreen(props: IChatsAndChannelsScreenProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <WithoutHeader showBack>
                <ChatsAndChannels {...props} />
            </WithoutHeader>
        );
    }

    return (
        <DesktopLayout>
            <ChatsAndChannels {...props} />
        </DesktopLayout>
    );
}
