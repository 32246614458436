import { ICollectionsChatBundleRequest } from 'src/types/Collections';

import { getCollections, updateCollection } from './agent';

class CollectionService {
    queryKey = 'collections';

    getPublicCollections = async (city?: string, tags?: string[]) => {
        try {
            const { data } = await getCollections({ is_public: true, city, tags });

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getAllCollections = async (city?: string, tags?: string[]) => {
        try {
            const { data } = await getCollections({ city, tags });

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getPrivateCollection = async (projectId: number) => {
        try {
            const { data } = await getCollections({ is_public: false, project_id: projectId });

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    updateCollection = async (collection: ICollectionsChatBundleRequest) => {
        try {
            const { data } = await updateCollection(collection);

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new CollectionService();
