import { Box, Chip } from '@mui/material';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import Title from 'src/components/Title';

import BageSuccessIcon from './BageSuccess';

type ChannelsCountProps = {
    total?: number;
    current?: number;
    title: string;
};

const ChannelsCount = ({ current = 0, total = 0, title }: ChannelsCountProps) => {
    let icon = <></>;
    let isFull = false;
    const isMobile = useIsMobileSize();

    if (current > 0 && current === total) {
        icon = (
            <Box ml={0.5}>
                <BageSuccessIcon />
            </Box>
        );
        isFull = true;
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Title>{title}</Title>
            {total > 0 && (
                <Chip
                    icon={icon}
                    color={'primary'}
                    variant={isFull ? 'filled' : 'outlined'}
                    label={`${current} из ${total}`}
                    sx={{
                        background: (!isFull && !isMobile) ? '#fff' : null,
                    }}
                />
            )}
        </Box>
    );
};

export default ChannelsCount;
