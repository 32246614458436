import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { DesktopLayout, WithoutHeader } from 'src/layout';

import FinishCreateProject, { IFinishCreateProjectProps } from './FinishCreateProjectContent';

export default function FinishCreateProjectScreen(props: IFinishCreateProjectProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <WithoutHeader showMyHeader={false}>
                <FinishCreateProject {...props} />
            </WithoutHeader>
        );
    }

    return (
        <DesktopLayout>
            <FinishCreateProject {...props} />
        </DesktopLayout>
    );
}
