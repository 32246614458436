import { useEffect } from 'react';
import { Box, Typography, Button, Chip } from '@mui/material';
import { Redirect, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import TicketField from 'src/components/TicketField';
import { WithoutHeader, DesktopLayout } from 'src/layout';
import getPeriodsInfo from 'src/utils/getPeriodInfo';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import useSubscriptionsStore from 'src/stores/subscriptions';
import useUserStore from 'src/stores/user';
import SubscriptionsService from 'src/services/SubscriptionsService';
import { ROUTES_URL } from 'src/constants/routes';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';

import Title from 'src/components/Title';

import PeriodField from './components/PeriodField';
import PaymentMethods from './components/PaymentMethods';
import Points from './components/Points';
import formatNumber from 'src/utils/formatNumber';

type PaymentForm = {
    plan_id: number;
    duration_months: number;
    paymentMethod: string;
    bonus: boolean;
    promo_code?: string;
};

export default function PaymentScreen() {
    const { state } = useLocation();
    const isMobile = useIsMobileSize();

    const { mutate: createPayment, isLoading: isCreatePaymentLoading } = useMutation(
        SubscriptionsService.queryKey,
        SubscriptionsService.createPayment,
        {
            onSuccess: (data) => {
                document.location = data.confirmation_url;
            },
            onError: () => {
                enqueueSnackbar({
                    variant: 'error',
                    message: 'Произошла ошибка при создании платежа. Попробуйте повторить позднее',
                });
            },
        }
    );

    const { control, handleSubmit, watch, setValue } = useForm<PaymentForm>({
        defaultValues: {
            plan_id: 1,
            duration_months: (state as { period?: number })?.period || 1,
            paymentMethod: 'UMoney',
            bonus: false,
            promo_code: '',
        },
    });

    const { selectedPlan, promoSale, subscriptions } = useSubscriptionsStore(
        ({ selectedPlan, promoSale, subscriptions }) => ({ selectedPlan, promoSale, subscriptions })
    );

    const { bonusPoints } = useUserStore(({ bonusPoints }) => ({ bonusPoints }));
    const subscription = subscriptions.filter((s) => s.id === selectedPlan)?.[0];

    useEffect(() => {
        if (subscription && subscription.id) {
            setValue('plan_id', subscription.id);
        }
    }, [subscription?.id]);

    if (!subscription) return <Redirect to={ROUTES_URL.SUBSCRIPTIONS} />;

    const periods = Object.values(getPeriodsInfo(subscription.price));
    const periodId = watch('duration_months');
    const isUseBonus = watch('bonus');
    const period = periods.filter((item) => item.value === periodId)?.[0];
    let total = period?.priceWithSale || 0;
    let promocodeDiff = 0;

    if (promoSale > 0) {
        promocodeDiff = total;
        total = total - (total / 100) * 20;
        promocodeDiff = promocodeDiff - total;
    }

    if (isUseBonus) {
        total = total - bonusPoints;
    }

    const onSubmit: SubmitHandler<PaymentForm> = (data) => {
        const body = {
            plan_id: data.plan_id,
            duration_months: data.duration_months,
        };

        if (data.promo_code && promoSale > 0) {
            body['promo_code'] = data.promo_code;
        }

        if (data.bonus) {
            body['use_bonus_points'] = bonusPoints;
        }

        createPayment(body);
    };

    const renderContent = () => {
        return (
            <LoadingWrapper isLoading={isCreatePaymentLoading}>
                <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="100%"
                    flexGrow={1}
                >
                    <Box mb={3}>
                        <Title>Оплата</Title>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box>
                            <Box mb={1}>
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    Период
                                </Typography>
                            </Box>
                            <Box mb={1}>
                                <PeriodField
                                    periods={periods}
                                    control={control}
                                    name="duration_months"
                                />
                            </Box>
                            {/* <Box mb={1.5}>
                    <Typography color="secondary" variant="body2" >Скидки действует до 28.08.24</Typography>
                </Box> */}
                            <Box mb={1}>
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    Способ оплаты
                                </Typography>
                            </Box>
                            <Box mb={1}>
                                <PaymentMethods
                                    name="paymentMethod"
                                    control={control}
                                    methods={[
                                        {
                                            name: 'Ю Money',
                                            value: 'UMoney',
                                            currency: 'RUB',
                                        },
                                    ]}
                                />
                            </Box>
                            <Box mb={1}>
                                <Points
                                    name="bonus"
                                    control={control}
                                    points={bonusPoints}
                                />
                            </Box>
                            <Box mb={1}>
                                <TicketField
                                    name="promo_code"
                                    control={control}
                                    placeholder="Промокод"
                                />
                            </Box>
                            <Box mb={1}>
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    К оплате
                                </Typography>
                            </Box>
                            <Box mb={1}>
                                <Box
                                    border="1px solid #E3ECF3"
                                    bgcolor="#E3ECF3"
                                    borderRadius={4}
                                >
                                    {isUseBonus && (
                                        <Box
                                            px={12 / 8}
                                            py={1}
                                            borderBottom="1px solid #E3ECF3"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                            >
                                                Баллы
                                            </Typography>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    color="primary"
                                                >
                                                    -{bonusPoints} Б
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {promoSale > 0 && (
                                        <Box
                                            px={12 / 8}
                                            py={1}
                                            borderBottom="1px solid #E3ECF3"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                            >
                                                Промокод
                                            </Typography>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    color="primary"
                                                >
                                                    -{formatNumber(promocodeDiff)} ₽
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {period.value > 1 && (
                                        <Box
                                            px={12 / 8}
                                            py={1}
                                            borderBottom="1px solid #E3ECF3"
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                            >
                                                Акция
                                            </Typography>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <Chip
                                                    label={`${period.sale}%`}
                                                    size="small"
                                                />
                                                <Typography
                                                    variant="body1"
                                                    color="primary"
                                                >
                                                    -{formatNumber(period.price - period.priceWithSale)} ₽
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {total > 0 && (
                                        <Box
                                            px={12 / 8}
                                            py={1}
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                            >
                                                Итого
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                color="primary"
                                            >
                                                {formatNumber(total)} ₽
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Оплатить
                        </Button>
                    </form>
                </Box>
            </LoadingWrapper>
        );
    };

    if (!isMobile) {
        return <DesktopLayout>{renderContent()}</DesktopLayout>;
    }

    return <WithoutHeader showBack>{renderContent()}</WithoutHeader>;
}
