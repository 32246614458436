import { Box, Typography } from '@mui/material';
import { WithoutHeader } from 'src/layout';
import SelectEntityForm from 'src/widgets/SelectEntityForm';

const SelectCollections = () => {
    return (
        <WithoutHeader showBack>
            <Box mb={2.5}>
                <Typography variant="h2">Создание проекта</Typography>
                <Typography variant="h2">2. Подборки</Typography>
            </Box>
            <SelectEntityForm
                options={[]}
                name="chats"
                buttonTitle="Далее"
            />
        </WithoutHeader>
    );
};

export default SelectCollections;
