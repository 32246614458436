import PublicLayout from 'src/layout/PublicLayout';
import { LayoutPaper } from 'src/layout';
import RegisterForm from 'src/widgets/RegisterForm';

export default function Register() {
    return (
        <LayoutPaper>
            <PublicLayout>
                <RegisterForm />
            </PublicLayout>
        </LayoutPaper>
    );
}
