import { useQuery } from 'react-query';

import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import useScrollToTop from 'src/hooks/useScrollToTop';
import useSessionStore from 'src/stores/session';

interface IAuthProviderProps {
    children: React.ReactNode;
}

export default function AuthProvider({ children }: IAuthProviderProps) {
    useScrollToTop();

    const { getUserSessionRequiredData, isAuthorized } = useSessionStore(
        ({ isAuthorized, getUserSessionRequiredData }) => ({
            isAuthorized,
            getUserSessionRequiredData,
        })
    );

    const { isLoading: isUserLoading } = useQuery('user', getUserSessionRequiredData, {
        refetchOnMount: true,
    });

    return (
        <LoadingWrapper
            isLoading={isUserLoading}
            isForced={!isAuthorized}
        >
            {children}
        </LoadingWrapper>
    );
}
