import Lock from 'src/icons/Lock';
import Mail from 'src/icons/Mail';
import Exit from 'src/icons/Exit';
import { Info } from 'src/icons/InfoXs';
import PenSqure from 'src/icons/PenSqure';
import Bookmark from 'src/icons/Bookmark';
import Share from 'src/icons/Share';
import sessionStore from 'src/stores/session';
import userStore from 'src/stores/user';
import restorePasswordStore from 'src/stores/restorePassword';
import { history } from 'src/utils/history';
import EventService from 'src/services/EventService';
import { ROUTES_URL } from 'src/constants/routes';

import Papper from 'src/icons/Papper';

import { AccordeonMenuItem } from './components/AccordeonMenu';

const menuList: AccordeonMenuItem[] = [
    {
        title: 'Изменить пароль',
        icon: <Lock />,
        onClick: () => {
            const { email } = userStore.getState();
            restorePasswordStore.getState().setEmail(email);

            history.push(ROUTES_URL.RESTORE_PASSWORD);
            EventService.dispatch('CLOSE_MENU');
        },
    },
    {
        title: 'Изменить почту',
        icon: <Mail />,
        onClick: () => {
            history.push(ROUTES_URL.CHANGE_EMAIL);
            EventService.dispatch('CLOSE_MENU');
        },
    },
    {
        title: 'Поддержка',
        icon: <Info />,
        onClick: () => {
            window.open('https://t.me/tghunter_manager');
        },
    },
    {
        title: 'О приложении',
        icon: <Papper />,
        onClick: () => {
            window.open('https://tghunter.ru/');
        },
    },
    {
        title: 'Обратная связь',
        icon: <PenSqure />,
        customActionIcon: <Share />,
        onClick: () => {
            window.open('mailto:business@tghunter.ru');
        },
    },
    {
        title: 'Выйти из учётной записи',
        icon: <Exit />,
        customActionIcon: <div />,
        onClick: () => {
            const store = sessionStore.getState();
            EventService.dispatch('CLOSE_MENU');
            history.push(ROUTES_URL.LOGIN);

            store.logout();
        },
    },
];

export const PAGES: AccordeonMenuItem[] = [
    {
        title: 'Избранное',
        icon: <Bookmark />,
        onClick: () => {
            history.push(ROUTES_URL.FAVORITES);
            EventService.dispatch('CLOSE_MENU');
        },
    },
];

export default menuList;
