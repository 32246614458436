import { Box, Typography } from '@mui/material';
import CenterScreen from 'src/components/CenterScreen';
import NotSignal from 'src/icons/NotSignal';
import { LayoutWhite } from 'src/layout';

const NotSignalScreen = () => {
    return (
        <LayoutWhite>
            <CenterScreen>
                <Box mb={2}>
                    <NotSignal />
                </Box>
                <Box mb={1}>
                    <Typography variant="h4">Нет связи</Typography>
                </Box>
                <Box color="#647991">
                    <Typography variant="body2">Проверьте доступ к интернету</Typography>
                </Box>
            </CenterScreen>
        </LayoutWhite>
    );
};

export default NotSignalScreen;
