import globalAgent from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { AxiosPromise } from 'axios';
import type { IChatBody } from 'src/types/Chats';


export const fetchChats = ():AxiosPromise<IChatBody[]> => {
    return globalAgent.get(URLS.CHATS);
};
