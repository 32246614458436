import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import SessionService from 'src/services/SessionService';
import UserSevice from 'src/services/UserSevice';
import { getBeginWorkStepOrder } from 'src/utils/beginWork';

import type { IUser, IUserResponse, StepsType } from 'src/types/User';
import type { CurrentSubscription } from 'src/types/Subscription';

type IState = IUser;

interface IAction {
    setOnboardingInfo: (onboardingStep: StepsType, isOnboardingCompleted: boolean) => void;
    updateStep: (data: StepsType) => Promise<unknown>;

    confirmEmail: (code: string) => Promise<unknown>;
    setEmail: (email: string) => void;
    refetchEmailCode: () => Promise<void>;

    clearUser: () => void;

    setUserInfo: (values: IUserResponse) => void;
    getUserInfo: () => IState;
    updateSubscription: (value: CurrentSubscription) => void;
}

const useUserStore = create<IState & IAction>(
    (set, get) => ({
        id: 0,

        role: '',

        username: '',

        email: '',

        bonusPoints: 0,

        isEmailConfirmed: false,

        confirmEmailLoading: false,

        isOnboardingCompleted: false,

        onboardingStep: 1,

        current_subscription: null,

        setEmail: (email: string) => set({ email }),

        clearUser: () =>
            set({
                id: 0,
                email: '',
                isOnboardingCompleted: false,
                isEmailConfirmed: false,
                onboardingStep: 1,
                current_subscription: null,
            }),

        confirmEmail: async (code) => {
            try {
                await SessionService.confirmEmail({
                    code,
                    email: get().email,
                });

                set({ isEmailConfirmed: true });

                return Promise.resolve();
            } catch (error) {
                console.log(error);

                return Promise.reject(error);
            }
        },

        refetchEmailCode: async () => {
            try {
                await SessionService.refetchEmailCode();
                return Promise.resolve();
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },

        updateStep: async (data) => {
            const { onboardingStep, isOnboardingCompleted } = get();
            try {
                if (isOnboardingCompleted) return;

                set({
                    onboardingStep: getBeginWorkStepOrder('completed', onboardingStep),
                    isOnboardingCompleted: false,
                });

                const { onboarding_step, is_onboarding_completed } = await UserSevice.updateOnboardingStep(data);

                if (!is_onboarding_completed) {
                    set({
                        onboardingStep: getBeginWorkStepOrder(onboarding_step, onboardingStep),
                        isOnboardingCompleted: is_onboarding_completed,
                    });
                }

                return Promise.resolve();
            } catch (error) {
                set({
                    onboardingStep: onboardingStep,
                    isOnboardingCompleted: false,
                });

                return Promise.reject(error);
            }
        },

        setOnboardingInfo: (onboardingStep, isOnboardingCompleted) => {
            set({
                onboardingStep: getBeginWorkStepOrder(onboardingStep, get().onboardingStep),
                isOnboardingCompleted,
            });
        },

        setUserInfo: ({
            bonus_points: bonusPoints,
            is_email_confirmed: isEmailConfirmed,
            is_onboarding_completed: isOnboardingCompleted,
            onboarding_step,
            current_subscription,
            ...values
        }) => {
            set({
                ...values,
                isEmailConfirmed,
                bonusPoints,
                isOnboardingCompleted,
                onboardingStep: getBeginWorkStepOrder(onboarding_step, 1),
                current_subscription,
            });
        },

        getUserInfo: () => {
            const {
                id,
                email,
                username,
                role,

                bonusPoints,
                isEmailConfirmed,
                isOnboardingCompleted,
                onboardingStep,
                current_subscription,
            } = get();
            return {
                id,
                email,
                username,
                role,

                bonusPoints,
                isOnboardingCompleted,
                isEmailConfirmed,
                onboardingStep,
                current_subscription,
            };
        },

        updateSubscription: (value: CurrentSubscription) => {
            set({
                current_subscription: value,
            });
        },
    }),
    shallow
);

export default useUserStore;
