import { Box, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import AddItemField from 'src/widgets/AddItemField';
import TitleWithCounter from 'src/widgets/TitleWithCounter';
import Link from 'src/icons/Link';
import ListEntities from 'src/widgets/ListEntities';
import Dialog from 'src/icons/Dialog';
import useUserStore from 'src/stores/user';
import useCollectionsStore from 'src/stores/collections';
import CollectionService from 'src/services/CollectionService';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import { getBeginWorkStepByOrder } from 'src/utils/beginWork';
import { IBeginWorkOnSuccessArgs } from 'src/types';

const EmptyList = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={10}
        >
            <Box mb={1}>
                <Dialog />
            </Box>
            <Box mb={0.5}>
                <Typography
                    variant="body1"
                    color="primary"
                    textAlign="center"
                >
                    Добавьте чаты
                </Typography>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    color="secondary"
                    textAlign="center"
                >
                    Ссылку на чат можно взять в описании чата, для закрытов чатов скопируйте ссылку приглашения
                </Typography>
            </Box>
        </Box>
    );
};
export interface IChat {
    id: number | string;
    name: string;
}

export interface IChatsAndChannelsScreenProps {
    onSuccess?: ({ skiped }: IBeginWorkOnSuccessArgs) => void;
}

export default function ChatsAndChannelsScreen({ onSuccess }: IChatsAndChannelsScreenProps) {
    const privateCollection = useCollectionsStore(({ privateCollection }) => privateCollection);

    const [items, setItems] = useState<IChat[]>(
        privateCollection?.chats?.map(({ id, telegram_link }, index) => ({
            id: id || index,
            name: telegram_link,
        })) || []
    );

    const { chatsLimit, onboardingStep } = useUserStore(({ current_subscription, onboardingStep }) => ({
        chatsLimit: current_subscription?.plan.chats_limit || 0,
        onboardingStep,
    }));

    const currentCount = items.length;
    const isMaxCount = currentCount >= chatsLimit;

    const hasSkipAbility = getBeginWorkStepByOrder(onboardingStep)?.id === 'add_chats';
    const normalizeTelegramLink = (link) => {
        if (!link) return '';
    
        link = link.trim();
        link = link.replace(/@/, '');
        link = link.replace(/^https?:\/\/t\.me\//, '');
        link = link.replace(/^t\.me\//, '');

        return `https://t.me/${link}`;
    }

    const onAddChat = (value: string) => {
        try {
            const link = normalizeTelegramLink(value);
            setItems((prevState) => {
                if (prevState.some(({ name }) => name === link)) {
                    enqueueSnackbar({ message: 'Чат уже добавлен!', variant: 'info' });
                    return prevState;
                }
                return [...prevState, { name: link, id: prevState.length + 1 }];
            });
        } catch (_err) {
            enqueueSnackbar('Введите корректную ссылку', { variant: 'error' });
        }
    };

    const { mutate: updateCollection, isLoading: isUpdateCollectionLoading } = useMutation(
        CollectionService.queryKey,
        useCollectionsStore.getState().updateCollection,
        {
            onSuccess: () => {
                onSuccess?.({ skiped: false });
                enqueueSnackbar('Чаты и каналы сохранены', { variant: 'success' });
            },
            onError: (error) => {
                console.log(error);
                enqueueSnackbar({
                    message: 'Ошибка сохранения чатов и каналов, проверьте введенные данные',
                    variant: 'error',
                });
            },
        }
    );

    const onDeleteChat = (chat: IChat) => {
        setItems((prevState) => prevState.filter(({ id }) => id !== chat.id));
    };

    const onSaveHandler = () => {
        if (hasSkipAbility && !currentCount) {
            onSuccess?.({ skiped: true });
            return;
        }

        updateCollection({
            id: privateCollection.id,
            name: privateCollection.name,
            owner: useUserStore.getState().id,
            chats: items.map(({ name }) => ({
                telegram_link: name,
            })),
        });
    };

    return (
        <LoadingWrapper isLoading={isUpdateCollectionLoading}>
            <Box
                pt={2}
                minHeight="100%"
                display="flex"
                flexDirection="column"
            >
                <Box mb={1.5}>
                    <TitleWithCounter
                        title="Чаты и каналы"
                        current={currentCount}
                        total={chatsLimit}
                    />
                </Box>
                <Box
                    maxWidth="80%"
                    mb={20 / 8}
                >
                    <Typography
                        variant="body1"
                        color="primary"
                    >
                        Добавляйте чаты с потенциальными клиентами по вашем услугам
                    </Typography>
                </Box>

                <Box mb={3}>
                    <AddItemField
                        icon={<Link />}
                        placeholder="Добавьте ссылку на канал"
                        onAddItem={onAddChat}
                        disabled={isMaxCount}
                    />
                </Box>
                {currentCount ? (
                    <ListEntities
                        entities={items}
                        onDeleteItem={onDeleteChat}
                    />
                ) : (
                    <EmptyList />
                )}
                <Box
                    display="flex"
                    alignItems="flex-end"
                    flexGrow={1}
                    pb={1}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={onSaveHandler}
                    >
                        {hasSkipAbility ? (currentCount ? 'Продолжить' : 'Пропустить') : 'Сохранить'}
                    </Button>
                </Box>
            </Box>
        </LoadingWrapper>
    );
}
