import { object, string, TypeOf } from 'zod';

const LoginScheme = object({
    email: string().min(1, 'Email обязательное поле').email('Поле Email не валидно'),
    password: string().min(1, 'Поле пароля обязательное'),
});

export type TypeLoginScheme = TypeOf<typeof LoginScheme>;

export default LoginScheme;
