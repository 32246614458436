import { Box, Typography } from '@mui/material';
import CenterScreen from 'src/components/CenterScreen';
import SearchChannel from 'src/icons/SearchChannel';
import { LayoutPaper, DesktopLayout } from 'src/layout';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

const ScaningChannelsScreen = () => {
    const isMobile = useIsMobileSize();

    const renderContent = () => {
        return (
            <>
                <Box mb={2}>
                    <SearchChannel />
                </Box>
                <Box mb={1}>
                    <Typography variant="h4">Запускаем мониторинг…</Typography>
                </Box>
                <Box
                    color="#647991"
                    width="65%"
                    textAlign="center"
                >
                    <Typography variant="body2">
                        Подключаемся к выбранным чатам и начинаем отслеживать сообщения по ключевым словам
                    </Typography>
                </Box>
            </>
        );
    };

    if (!isMobile) {
        return (
            <DesktopLayout>
                <CenterScreen>
                    {renderContent()}
                </CenterScreen>
            </DesktopLayout>
        );
    }

    return (
        <LayoutPaper isWhite>
            <CenterScreen>
                {renderContent()}
            </CenterScreen>
        </LayoutPaper>
    );
};

export default ScaningChannelsScreen;
