import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import SessionService from 'src/services/SessionService';

type RestorePasswordStore = {
    email: string;
    code: string;
    new_password: string;
    new_password2: string;
    isLoading: boolean;
    clear: () => void;
    setEmail: (email: string) => void;
    setCode: (code: string) => void;
    restorePassword: () => Promise<unknown>;
    confirmResetPassword: (data: { new_password: string; new_password2: string }) => Promise<unknown>;
};

const useRestorePasswordStore = create<RestorePasswordStore>(
    (set, get) => ({
        code: '',
        email: '',
        new_password: '',
        new_password2: '',
        isLoading: false,
        clear: () => {
            set({
                code: '',
                email: '',
                new_password: '',
                new_password2: '',
            });
        },
        setCode: (code) => {
            set({ code });
        },
        setEmail: (email) => {
            set({ email });
        },
        restorePassword: async () => {
            try {
                set({
                    isLoading: true,
                });

                await SessionService.resetPassword({ email: get().email });

                set({
                    isLoading: false,
                });
            } catch (error) {
                set({
                    isLoading: false,
                });

                return Promise.reject(error);
            }
        },
        confirmResetPassword: async (data) => {
            try {
                set({
                    isLoading: true,
                });

                await SessionService.confirmResetPassword({
                    password: data.new_password,
                    password2: data.new_password2,
                    code: get().code,
                    email: get().email,
                });
                get().clear();
            } catch (error) {
                set({
                    isLoading: false,
                });

                return Promise.reject(error);
            }
        },
    }),
    shallow
);

export default useRestorePasswordStore;
