import axios, { AxiosHeaders } from 'axios';

import { API_URL, URLS } from 'src/constants/urls';
import SessionService from 'src/services/SessionService';
import { history } from 'src/utils/history';
import useSessionStore from 'src/stores/session';

import { enqueueSnackbar } from 'notistack';

import { ROUTES_URL } from 'src/constants/routes';

import ForbiddenError from './ForbiddenError';

import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const defaultConfig: AxiosRequestConfig = {
    baseURL: API_URL,
    withCredentials: true,
};

const defaultHeaders: AxiosRequestHeaders = new AxiosHeaders();

export function setDefaultHeaders(key: string, value: string) {
    defaultHeaders[key] = value;
}

export function removeHeaderFromDefaults(key: string | string[]) {
    defaultHeaders.delete(key);
}

export function createAxiosAgent(config: AxiosRequestConfig = {}) {
    const instance = axios.create({ ...defaultConfig, ...config });
    return instance;
}

const globalAgent = createAxiosAgent();

globalAgent.interceptors.request.use(function (config) {
    config.headers = new AxiosHeaders({
        ...defaultHeaders,
        ...config.headers,
    });
    return config;
});

globalAgent.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig?.url === URLS.SIGN_IN && !err.response.status) {
            return;
        }
        if (err.response?.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
                await SessionService.updateToken();
                originalConfig.headers['Authorization'] = globalAgent.defaults.headers['Authorization'];
                return globalAgent(originalConfig);
            } catch (error) {
                useSessionStore.getState().logout();
                return Promise.reject(error);
            }
        }

        if (err.response?.status === 404) {
            history.push(ROUTES_URL.NOT_FOUND);
            throw new ForbiddenError('Доступ к данной странице запрещен');
        }

        if (err.response?.status === 403) {
            const serverMessage = err.response?.data?.message;
            const message = typeof serverMessage === 'string' ? serverMessage : 'Запрашиваемая страница недоступна!';
            enqueueSnackbar({
                message,
                variant: 'error',
            });
        }

        return Promise.reject(err);
    }
);

export default globalAgent;
