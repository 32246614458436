import { useState } from 'react';
import { Accordion, Typography, AccordionSummary, Box, styled, accordionSummaryClasses } from '@mui/material';

import ChevroneUp from 'src/icons/ChevroneUp';

import { IFilterScheme } from '../utils';

import FilterForm from './FilterForm';

const Wrapper = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: 'none',
    boxShadow: 'none',
    margin: '0!important',
}));

const Title = styled(AccordionSummary)(
    ({ theme }) => `
    background-color: ${theme.palette.background.default};
    border: none;
    box-shadow: none;
    font-weight: bold;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0   
    min-height: 48px;

    &.${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded} {
        min-height: 48px;
    };

    &.${accordionSummaryClasses.expanded} {
        margin:0;
        min-height: 48px;
    };
    
   & .${accordionSummaryClasses.content} {
        margin: 0   
    };
}`
);

export default function FilterItem({ title, withExpanded, ...props }: IFilterScheme) {
    const [expanded, setExpanded] = useState<string | false>(false);

    if (props.options.length < 1) {
        return null;
    }

    const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const isExpanded = expanded === title;

    return (
        <Box key={title}>
            <Wrapper
                onChange={handleChange(title)}
                expanded={withExpanded ? undefined : true}
            >
                {withExpanded && (
                    <Title
                        expandIcon={<ChevroneUp />}
                        aria-controls={title}
                        id={title}
                    >
                        <Typography
                            fontWeight="600"
                            variant="h4"
                        >
                            {title}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="secondary"
                            ml="auto"
                            mr={1}
                        >
                            {isExpanded ? 'Скрыть' : 'Показать'}
                        </Typography>
                    </Title>
                )}

                <FilterForm {...props} />
            </Wrapper>
        </Box>
    );
}
