import { forwardRef } from 'react';
import { type CustomContentProps, MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    // тут можно цвета добавить
    // '&.notistack-MuiContent-success': {
    //     backgroundColor: '#2D7738',
    // },
    // '&.notistack-MuiContent-error': {
    //     backgroundColor: '#970C0C',
    // },
}));

const Snackbar = forwardRef<HTMLDivElement | null, CustomContentProps>((props, ref) => {
    return (
        <StyledMaterialDesignContent
            {...props}
            ref={ref}
        />
    );
});

export default Snackbar;
