import { Box, Button, styled, Typography } from '@mui/material';
import useGoTo from 'src/hooks/useGoTo';

import OpenedBook from 'src/icons/OpenedBook';

interface IEmptyProps {
    icon?: React.ReactNode;
    text?: string;
    withMainButton?: boolean;
}

const Wrapper = styled(Box)`
    & > div:first-child {
        width: 80px;
        height: 80px;
        & svg {
            width: 100%;
            height: 100%;
        }
    }
`;

export default function Empty({
    icon = <OpenedBook />,
    text = 'Ничего не найдено',
    withMainButton = true,
}: IEmptyProps) {
    const goTo = useGoTo();

    const onMainButtonClick = () => {
        goTo('/');
    };

    return (
        <Wrapper
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={20}
            minHeight={200}
            gap={2}
        >
            <Box>{icon}</Box>
            <Box>
                <Typography
                    variant="h2"
                    color="secondary"
                    textAlign="center"
                >
                    {text}
                </Typography>
            </Box>
            {withMainButton && (
                <Button
                    onClick={onMainButtonClick}
                    variant="contained"
                    fullWidth={false}
                    size="large"
                >
                    На главную
                </Button>
            )}
        </Wrapper>
    );
}
