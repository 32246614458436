export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM11.416 5.77735L11.6934 5.36132L10.8613 4.80662L10.584 5.22265L7.53982 9.78888C7.32687 10.1083 6.84827 10.0785 6.67658 9.73514L5.44721 7.27639C5.32372 7.0294 5.02338 6.92929 4.77639 7.05279C4.5294 7.17628 4.42929 7.47662 4.55279 7.72361L5.78216 10.1823C6.29723 11.2125 7.73301 11.3019 8.37187 10.3436L11.416 5.77735Z"
            fill="white"
        />
    </svg>
);
