import { Box, Button, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import CustomInputField from 'src/components/CustomInputField';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import SimpleList from 'src/components/SimpleList';
import { ROUTES_URL } from 'src/constants/routes';
import useGoTo from 'src/hooks/useGoTo';
import useCollectionsStore from 'src/stores/collections';
import useProjectsStore from 'src/stores/projects';
import TitleWithCounter from 'src/widgets/TitleWithCounter';

export interface ICreateProjectProps {
    onSuccess?: () => void;
}

export default function CreateProjectContent({ onSuccess }: ICreateProjectProps) {
    const goTo = useGoTo();

    const activeProject = useProjectsStore(({ activeProject }) => activeProject);
    const privateCollection = useCollectionsStore(({ privateCollection }) => privateCollection);

    const [projectName, setProjectName] = useState(activeProject?.name || '');

    const { mutate: udapteProject, isLoading: isUdapteProjectLoading } = useMutation(
        useProjectsStore.getState().udapteProject,
        {
            onSuccess: () => {
                if (onSuccess) {
                    onSuccess();
                    return;
                }
                goTo(ROUTES_URL.CREATE_PROJECT_FINISH);
            },
            onError: (error) => {
                console.log(error);
                enqueueSnackbar({ message: 'Ошибка сохранения ключевые слов', variant: 'error' });
            },
        }
    );

    const onChangeNameHandler = (value: string) => {
        setProjectName(value);
    };

    const onSubmitClickHandler = () => {
        udapteProject({
            name: projectName.trim(),
        });
    };

    const { chats } = privateCollection;

    const { keywords = [], bundles = [] } = activeProject || {};

    const projectData = useMemo(
        () => [
            {
                title: 'Подборки',
                items: bundles.map(({ name }) => name),
            },
            {
                title: 'Чаты и группы',
                items: chats.map(({ telegram_link }) => telegram_link),
            },
            {
                title: 'Ключевые слова',
                items: keywords.map(({ word }) => word),
            },
        ],
        []
    );

    return (
        <LoadingWrapper isLoading={isUdapteProjectLoading}>
            <Box
                display="flex"
                minHeight="100%"
                flexDirection="column"
            >
                <Box mb={1.5}>
                    <TitleWithCounter title="Создание проекта" />
                </Box>
                <Box
                    maxWidth="80%"
                    mb={20 / 8}
                >
                    <Typography
                        variant="body1"
                        color="primary"
                    >
                        Почти все готово! Осталось назвать ваш проект и запустить мониторинг
                    </Typography>
                </Box>

                <Box mb={1.5}>
                    <CustomInputField
                        placeholder="Название"
                        value={projectName}
                        onChange={onChangeNameHandler}
                        name="projectName"
                        icon="book"
                    />
                </Box>

                {projectData.map(({ title, items }) => (
                    <Box
                        mb={1.5}
                        key={title}
                    >
                        <SimpleList
                            title={title}
                            list={items}
                        />
                    </Box>
                ))}

                <Box
                    display="flex"
                    alignItems="flex-end"
                    flexGrow={1}
                    mb={1}
                >
                    <Button
                        onClick={onSubmitClickHandler}
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        Создать
                    </Button>
                </Box>
            </Box>
        </LoadingWrapper>
    );
}
