import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const localHistory: string[] = [];

history.listen(({ pathname }, method) => {
    if (method === 'PUSH' && localHistory.slice(-1)[0] !== pathname && pathname !== '/') {
        localHistory.push(pathname);
    } else if ((method === 'POP' || pathname === '/') && localHistory.length) {
        localHistory.pop();
    }
});

const originalHistoryGoBack = history.goBack;

history.goBack = () => {
    if (localHistory.length <= 1) {
        history.push('/');
        return;
    }

    originalHistoryGoBack();
};

export { history };
