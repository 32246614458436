import { Box, Typography, styled } from '@mui/material';

import ChevroneRight from 'src/icons/ChevroneRight';

import type { ReactNode } from 'react';

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    transition: 'all ease-in',
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.6,
    },
}));
const Icon = styled(Box)(() => ({
    width: 24,
    height: 24,
}));

const Action = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
}));

type ItemMenuProps = {
    onAction?: () => void;
    customActionComponent?: ReactNode;
    action?: boolean;
    icon?: ReactNode;
    title: string;
    subtitle?: string;
    button?: boolean;
};

const ItemMenu = ({
    title,
    icon = null,
    action = true,
    onAction = undefined,
    customActionComponent = null,
    subtitle = undefined,
    button = true,
}: ItemMenuProps) => {
    return (
        <Container
            onClick={onAction ? onAction : () => {}}
            sx={{
                cursor: button ? 'pointer' : 'default',
            }}
        >
            {icon && <Icon>{icon}</Icon>}
            <Box>
                <Typography
                    variant="body1"
                    color="primary"
                >
                    {title}
                </Typography>
                {subtitle && (
                    <Typography
                        variant="body2"
                        color="secondary"
                    >
                        {subtitle}
                    </Typography>
                )}
            </Box>
            {action && <Action>{customActionComponent ? customActionComponent : <ChevroneRight />}</Action>}
        </Container>
    );
};

export default ItemMenu;
