import globalAgent from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { TUpdateTProject, TProjectsResponse, IProjectResponse } from 'src/types/Projects';
import type { AxiosPromise } from 'axios';

export const getProjects = (): AxiosPromise<TProjectsResponse> => {
    return globalAgent.get(URLS.PROJECTS);
};

export const updateProject = ({ id, ...data }: TUpdateTProject): AxiosPromise<IProjectResponse> => {
    return globalAgent.patch(`${URLS.PROJECTS}${id}/`, data);
};

export const createProject = (data: TUpdateTProject): AxiosPromise<IProjectResponse> => {
    return globalAgent.post(URLS.PROJECTS_CREATE, data);
};
