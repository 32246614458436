export default {
    primary: {
        main: '#000',
        contrastText: '#fff',
    },
    secondary: {
        main: '#647991',
    },
    background: {
        default: '#fff',
        paper: '#EFF3F7',
    },
    success: {
        main: '#4AE287',
    },
    warning: {
        main: '#FAEB66',
    },
    error: {
        main: '#E43F3F',
        light: 'rgba(228, 63, 63, 0.2)',
    },
    action: {
        focusOpacity: 0.2,
        hoverOpacity: 0.2,
    },
    text: {
        primary: '#000',
        secondary: '#fff',
        disabled: '#9FA9BC',
    },
};
