import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
    open: boolean;
    onClose: () => void;
    onAccept: () => void;
    date: string;
}

export default function DialogConfirm({ open, onClose, onAccept, date }:Props) {
  const handleClose = () => {
    onClose();
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Подтверждение приостановки подписки"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" color="primary">Вы действительно хотите приостановить подписку? В случае подтверждения с {date} лидогенерация будет остановлена</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>Отменить</Button>
          <Button onClick={() => {
            handleClose();
            onAccept();
          }}>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
  );
}