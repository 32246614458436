import { FILTERED_BEGIN_WORK_STEPS } from 'src/constants/beginWorksSteps';
import { StepsType } from 'src/types/User';

export const getBeginWorkStepOrder = (step: StepsType, defaultValue = 1) => {
    return (
        FILTERED_BEGIN_WORK_STEPS.find(({ id }) => id.trim().toLocaleLowerCase() === step.trim().toLocaleLowerCase())
            ?.order || defaultValue
    );
};

export const getBeginWorkNextStepId = (step: StepsType) => {
    const currentIndex = FILTERED_BEGIN_WORK_STEPS.findIndex(
        ({ id }) => id.trim().toLocaleLowerCase() === step.trim().toLocaleLowerCase()
    );
    const nextIndex = currentIndex + 1;

    if (currentIndex < 0 || nextIndex >= FILTERED_BEGIN_WORK_STEPS.length) {
        return null;
    }

    return FILTERED_BEGIN_WORK_STEPS[nextIndex].id;
};

export const getBeginWorkStepByOrder = (stepOrder: number) => {
    return FILTERED_BEGIN_WORK_STEPS.find(({ order }) => order === stepOrder);
};
