import { ROUTES_URL } from './routes';

import type { StepsType } from 'src/types/User';

export interface IBeginWorkStep {
    order: number;
    id: StepsType;
    link?: string;
    type: 'page' | 'item';
}

export const BEGIN_WORK_STEPS_IDS = {
    EMAIL_CONFIRM: 'email_confirm',
    CHOOSE_PLAN: 'choose_plan',
    CHOOSE_COLLECTIONS: 'choose_collections',
    ADD_CHATS: 'add_chats',
    ADD_KEYWORDS: 'add_keywords',
    CREATE_PROJECT: 'create_project',
    COMPLETED: 'completed',
} as const;

export const BEGIN_WORK_STEPS: IBeginWorkStep[] = [
    {
        order: 1,
        id: BEGIN_WORK_STEPS_IDS.EMAIL_CONFIRM,
        type: 'item',
    },
    {
        order: 2,
        id: BEGIN_WORK_STEPS_IDS.CHOOSE_PLAN,
        link: ROUTES_URL.BEGIN_WORK_SUBSCRIPTIONS,
        type: 'item',
    },
    {
        order: 3,
        id: BEGIN_WORK_STEPS_IDS.CHOOSE_COLLECTIONS,
        link: ROUTES_URL.BEGIN_WORK_COLLECTIONS,
        type: 'item',
    },
    {
        order: 4,
        id: BEGIN_WORK_STEPS_IDS.ADD_CHATS,
        link: ROUTES_URL.BEGIN_WORK_CHATS_AND_CHANNELS,
        type: 'item',
    },
    {
        order: 5,
        id: BEGIN_WORK_STEPS_IDS.ADD_KEYWORDS,
        link: ROUTES_URL.BEGIN_WORK_KEYWORDS,
        type: 'item',
    },
    {
        order: 6,
        id: BEGIN_WORK_STEPS_IDS.CREATE_PROJECT,
        type: 'page',
        link: ROUTES_URL.BEGIN_WORK_CREATE_PROJECT,
    },
    {
        order: 7,
        id: BEGIN_WORK_STEPS_IDS.COMPLETED,
        type: 'page',
        link: ROUTES_URL.BEGIN_WORK_CREATE_PROJECT_FINISH,
    },
];

export const FILTERED_BEGIN_WORK_STEPS = BEGIN_WORK_STEPS.sort((current, next) => {
    if (current.order < next.order) {
        return -1;
    }

    return 1;
});

export const FILTERED_BEGIN_WORK_STEPS_ITEM_TYPE = FILTERED_BEGIN_WORK_STEPS.filter(({ type }) => type === 'item');

export const FILTERED_BEGIN_WORK_STEPS_PAGE_TYPE = FILTERED_BEGIN_WORK_STEPS.filter(({ type }) => type === 'page');
