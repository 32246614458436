import LogoIcon from 'src/icons/Logo';

import { NavLink } from 'react-router-dom';

import useStyles from './Logo.styles';

export default function Logo({ ...props }) {
    const classes = useStyles();
    return (
        <NavLink to={'/'} className={classes.link} {...props}>
            <LogoIcon />
        </NavLink>
    );
}
