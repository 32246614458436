import { Route, Redirect } from 'react-router-dom';

import useUserStore from 'src/stores/user';
import { FILTERED_BEGIN_WORK_STEPS } from 'src/constants/beginWorksSteps';
import { getBeginWorkStepByOrder } from 'src/utils/beginWork';

import type { RouteProps } from 'react-router-dom';

interface IProtectedRoute extends RouteProps {
    path: string;
    noCheckIsApproved?: boolean;
}

export default function BeginWorkProtectedRoute({ path, ...otherProps }: IProtectedRoute) {
    const { isOnboardingCompleted, onboardingStep } = useUserStore(({ isOnboardingCompleted, onboardingStep }) => ({
        isOnboardingCompleted,
        onboardingStep,
    }));

    if (isOnboardingCompleted) {
        return <Redirect to="/" />;
    }

    const stepOrderByPath = FILTERED_BEGIN_WORK_STEPS.find(({ link }) => link === path)?.order || onboardingStep;
    const maxStepOrder = getBeginWorkStepByOrder(onboardingStep)?.order || onboardingStep;

    if (stepOrderByPath > maxStepOrder) {
        return <Redirect to="/" />;
    }

    return (
        <Route
            path={path}
            {...otherProps}
        />
    );
}
