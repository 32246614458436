import { fetchKeywords } from './agent';

class KeywordsService {
    queryKey = 'keywords';

    fetch = async () => {
        try {
            const { data } = await fetchKeywords();

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new KeywordsService();
