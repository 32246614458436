import { Box } from '@mui/material';

import useIsMobileSize from 'src/hooks/useIsMobileSize';

import BottomSheetHeader from '../BottomSheetHeader';
import { getDateFilter } from '../FeedFilter/utils';
import FilterItem from '../FeedFilter/Components/FilterItem';

export default function DateSheet() {
    const isMobile = useIsMobileSize();
    const item = getDateFilter(isMobile);

    return (
        <Box px={1}>
            <BottomSheetHeader title="Дата сообщения" />
            <FilterItem
                key={item.title}
                {...item}
            />
        </Box>
    );
}
