export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4324_26527)">
            <g filter="url(#filter0_d_4324_26527)">
                <path d="M2 8C2 6.34315 3.34315 5 5 5H19C20.6569 5 22 6.34315 22 8V8.89642C22 9.56751 21.6048 10.1757 20.9915 10.4482C19.6469 11.0458 19.6469 12.9542 20.9915 13.5518C21.6048 13.8243 22 14.4325 22 15.1036V16C22 17.6569 20.6569 19 19 19H5C3.34315 19 2 17.6569 2 16V15.1036C2 14.4325 2.39522 13.8243 3.00847 13.5518C4.35309 12.9542 4.35309 11.0458 3.00847 10.4482C2.39522 10.1757 2 9.56751 2 8.89642V8Z" fill="#FAEB66" />
                <path d="M2.5 8C2.5 6.61929 3.61929 5.5 5 5.5H19C20.3807 5.5 21.5 6.61929 21.5 8V8.89642C21.5 9.36991 21.2211 9.799 20.7885 9.9913C19.0479 10.7649 19.0479 13.2351 20.7885 14.0087C21.2211 14.201 21.5 14.6301 21.5 15.1036V16C21.5 17.3807 20.3807 18.5 19 18.5H5C3.61929 18.5 2.5 17.3807 2.5 16V15.1036C2.5 14.6301 2.77885 14.201 3.21154 14.0087L3.00847 13.5518L3.21154 14.0087C4.95207 13.2351 4.95207 10.7649 3.21154 9.9913C2.77885 9.799 2.5 9.36991 2.5 8.89642V8Z" stroke="#F6D85E" />
            </g>
            <path d="M9.5 15.5L14.5 8.5" stroke="#1F1F1F" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 9.75C10.5 10.1642 10.1642 10.5 9.75 10.5C9.33579 10.5 9 10.1642 9 9.75C9 9.33579 9.33579 9 9.75 9C10.1642 9 10.5 9.33579 10.5 9.75ZM11.5 9.75C11.5 10.7165 10.7165 11.5 9.75 11.5C8.7835 11.5 8 10.7165 8 9.75C8 8.7835 8.7835 8 9.75 8C10.7165 8 11.5 8.7835 11.5 9.75ZM15 14.25C15 14.6642 14.6642 15 14.25 15C13.8358 15 13.5 14.6642 13.5 14.25C13.5 13.8358 13.8358 13.5 14.25 13.5C14.6642 13.5 15 13.8358 15 14.25ZM16 14.25C16 15.2165 15.2165 16 14.25 16C13.2835 16 12.5 15.2165 12.5 14.25C12.5 13.2835 13.2835 12.5 14.25 12.5C15.2165 12.5 16 13.2835 16 14.25Z" fill="#1F1F1F" />
        </g>
        <defs>
            <filter id="filter0_d_4324_26527" x="-4" y="-1" width="32" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.980392 0 0 0 0 1 0 0 0 0 0 0 0 0 0.6 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4324_26527" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4324_26527" result="shape" />
            </filter>
            <clipPath id="clip0_4324_26527">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

)