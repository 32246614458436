import { Route, Redirect } from 'react-router-dom';
import useSessionStore from 'src/stores/session';
import queryString from 'qs';

import ROUTES from 'src/router/routes';
import { ROUTES_URL } from 'src/constants/routes';

import useUserStore from 'src/stores/user';

import BeginWorkProtectedRoute from './BeginWorkProtectedRoute';

import type { RouteProps } from 'react-router-dom';

interface IProtectedRoute extends RouteProps {
    path: string;
    noCheckIsApproved?: boolean;
}

export default function ProtectedRoute({ path, ...otherProps }: IProtectedRoute) {
    const isAuthorized = useSessionStore(({ isAuthorized }) => isAuthorized);

    if (!isAuthorized) {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.LOGIN.path,
                    search: queryString.stringify(
                        {
                            backUrl: path,
                        },
                        { skipNulls: true }
                    ),
                }}
            />
        );
    }

    if (path.includes(ROUTES_URL.BEGIN_WORK)) {
        return (
            <BeginWorkProtectedRoute
                path={path}
                {...otherProps}
            />
        );
    }

    return (
        <Route
            path={path}
            {...otherProps}
        />
    );
}
