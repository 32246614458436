import { Box, IconButton, Typography } from '@mui/material';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import Cross from '../../icons/Cross';
import ArrowLeft from '../../icons/ArrowLeft';

import Title from '../Title';

import type { ReactNode } from 'react';

type FormWrapperProps = {
    children?: ReactNode;
    name?: string;
    onClose?: () => void;
    onButtonBack?: () => void;
    borderRadius?: number;
    topGutter?: boolean;
    sx?: Obj;
};

export default ({
    children,
    name = '',
    onButtonBack,
    onClose,
    borderRadius = 6,
    topGutter = false,

    sx = {},
}: FormWrapperProps) => {
    const isMobile = useIsMobileSize();
    return (
        <Box>
            <Box
                bgcolor={'background.default'}
                borderRadius={borderRadius}
                borderColor={isMobile ? 'none' : '#E3ECF3'}
                width={1}
                minHeight={48}
                px={2}
                pb={1}
                sx={{ ...sx }}
            >
                {topGutter && (
                    <Box
                        display="flex"
                        justifyContent={onButtonBack ? 'space-between' : 'flex-end'}
                        height={48}
                        marginRight={-2}
                    >
                        {onButtonBack && (
                            <IconButton
                                size="large"
                                onClick={onButtonBack}
                            >
                                <ArrowLeft />
                            </IconButton>
                        )}
                        {onClose && (
                            <IconButton
                                size="large"
                                onClick={onClose}
                            >
                                <Cross />
                            </IconButton>
                        )}
                    </Box>
                )}
                {name && (
                    <Box mb={3}>
                        <Title>{name}</Title>
                    </Box>
                )}
                {children}
            </Box>
        </Box>
    );
};
