export default () => (
    <svg
        width="16"
        height="24"
        viewBox="0 0 16 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5 14.5L8.70711 17.2929C8.31658 17.6834 7.68342 17.6834 7.29289 17.2929L4.5 14.5"
            stroke="#1F1F1F"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.5 9.5L7.29289 6.70711C7.68342 6.31658 8.31658 6.31658 8.70711 6.70711L11.5 9.5"
            stroke="#1F1F1F"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
