import useIsMobileSize from 'src/hooks/useIsMobileSize';
import { DesktopLayout, WithoutHeader } from 'src/layout';

import Collections, { ICollectionProps } from './Content';

export default function CollectionsScreen(props: ICollectionProps) {
    const isMobile = useIsMobileSize();

    if (isMobile) {
        return (
            <WithoutHeader showBack>
                <Collections {...props} />
            </WithoutHeader>
        );
    }

    return (
        <DesktopLayout>
            <Collections {...props} />
        </DesktopLayout>
    );
}
