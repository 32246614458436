import { useEffect, useId } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import SelectEntityForm from 'src/widgets/SelectEntityForm';

import type { IFilterOption, IFilterScheme } from '../utils';

type TFilterFormProps = Pick<IFilterScheme, 'options' | 'onSubmit' | 'defaultValues'>;

const renderFilterItem = (
    {
        id,
        subTitle,
        type,
        selectAllTitle,
        maxItems,
        defaultValues,
        items,
        withClearButton,
        withSubmitButton,
        withCustomForm,
    }: IFilterOption & { withCustomForm?: boolean },
    onSubmit?: TFilterFormProps['onSubmit']
) => {
    return (
        <Box
            key={id}
            display="flex"
            flexDirection="column"
            gap={1}
        >
            {subTitle && (
                <Typography
                    variant="body2"
                    color="secondary"
                >
                    {subTitle}
                </Typography>
            )}
            <SelectEntityForm
                type={type}
                name={String(id)}
                options={items.map(({ value, label }) => ({
                    id: value,
                    name: label,
                }))}
                withClearButton={withClearButton}
                withSubmitButton={withSubmitButton}
                allSelectedText={selectAllTitle}
                maxCount={maxItems}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                withCustomForm={withCustomForm}
            />
        </Box>
    );
};

export default function FilterForm({ options, onSubmit, defaultValues }: TFilterFormProps) {
    const id = useId();
    const isMobile = useIsMobileSize();

    const methods = useForm({
        defaultValues: defaultValues || {},
    });

    useEffect(() => {
        methods.reset(defaultValues);
    }, [JSON.stringify(defaultValues)]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
        >
            {options.map((item: IFilterScheme['options'][number], index) => {
                if (Array.isArray(item)) {
                    return (
                        <FormProvider
                            {...methods}
                            key={`${id}_${index}`}
                        >
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                {item.map((params: IFilterOption, index) => {
                                    return (
                                        <Fragment key={`${params.id}_${index}`}>
                                            {renderFilterItem({ ...params, withCustomForm: true }, onSubmit)}
                                        </Fragment>
                                    );
                                })}
                                {isMobile && <Button type="submit" variant="contained" color="primary" size="large" fullWidth>Применить</Button>}
                            </form>
                        </FormProvider>
                    );
                }
                return <Fragment key={item.id}>{renderFilterItem(item, onSubmit)}</Fragment>;
            })}
        </Box>
    );
}
