import { create } from 'zustand';

import MessagesService from 'src/services/MessagesService';

import type { IMessage, IMessagesRequest, IMessagesResponse } from 'src/types/Message';

interface IMessagesStore {
    messages: IMessage[];
    count: number;
    addMessages: (messages: IMessage[]) => void;
    removeMessage: (id: number) => Promise<void>;
    toggleFavorite: (params: { id: number; flag: boolean }) => Promise<void>;
    fetchMessages: (params?: IMessagesRequest) => Promise<IMessagesResponse>;
    deleteFromFavorite: (id: number) => void;
    clear: () => void;
}

export default create<IMessagesStore>((set, get) => ({
    messages: [],

    count: 0,

    addMessages: (messages: IMessage[]) =>
        set({
            messages: [...get().messages, ...messages],
        }),

    removeMessage: async (id) => {
        try {
            const { messages } = get();
            await MessagesService.removeMessage(id);

            set({ messages: messages.filter((message) => message.id !== id) });

            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    },

    toggleFavorite: async ({ id, flag }) => {
        try {
            await MessagesService.toggleFavoriteMessage(id, flag);
            const { messages } = get();

            set({
                messages: messages.map((item) => {
                    if (item.id === id) {
                        return { ...item, favorite: flag };
                    }
                    return item;
                }),
            });

            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    },

    fetchMessages: async (params = {} as IMessagesRequest) => {
        try {
            const { messages } = get();

            const { results, count, next } = await MessagesService.fetchMessages({
                ...params,
                limit: params.limit || 10,
            });

            set({ messages: [...messages, ...results], count });

            return Promise.resolve({ results, count, next });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    deleteFromFavorite: (id: number) => {
        const { messages } = get();

        set({ messages: messages.filter((message) => message.id !== id) });
    },
    clear: () => set({ messages: [], count: 0 }),
}));
