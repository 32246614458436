import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            minHeight: '100dvh',
            margin: '0 auto',
        },
        container: {
            backgroundColor: theme.palette.background.paper,
            [`@media (min-width: 768px)`]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100dvh',
            },
        },
        containerLight: {
            backgroundColor: theme.palette.background.default,
        },
    })
);
