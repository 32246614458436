import { useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as ru from 'dayjs/locale/ru';

import { ErrorBoundaryContext } from 'react-use-error-boundary';
import { Router as GlobalRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import ErrorBoundary from './components/ErrorBoundary';
import theme from './theme';
import Router from './router';
import BottomSheet from './components/BottomSheet';
import Menu from './widgets/Menu';
import { history } from './utils/history';
import AuthProvider from './providers/AuthProvider';
import SnackbarProvider from './providers/SnackbarProvider';
import UpdateBeginWorkStepsProvider from './providers/UpdateBeginWorkStepsProvider';
import { ROUTES_URL } from './constants/routes';

dayjs.locale(ru);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            retry: 0,
            refetchOnWindowFocus: false,
        },
    },
});

export const App = () => {
    const listenerIsOnline = () => {
        history.goBack();
    };

    const listenerIsOffline = () => {
        history.push(ROUTES_URL.NO_SIGNAL);
    };

    useEffect(() => {
        window.addEventListener('offline', listenerIsOffline);
        window.addEventListener('online', listenerIsOnline);
        return () => {
            window.removeEventListener('offline', listenerIsOffline);
            window.removeEventListener('online', listenerIsOnline);
        };
    }, []);

    return (
            <ErrorBoundaryContext>
                <ErrorBoundary>
                    <SnackbarProvider>
                        <ThemeProvider theme={theme}>
                            <QueryClientProvider client={queryClient}>
                                <GlobalRouter history={history}>
                                    <AuthProvider>
                                        <UpdateBeginWorkStepsProvider>
                                            <Router />
                                            <Menu />
                                            <BottomSheet />
                                            <YMInitializer accounts={[96484608]}/>
                                        </UpdateBeginWorkStepsProvider>
                                    </AuthProvider>
                                </GlobalRouter>
                            </QueryClientProvider>
                        </ThemeProvider>
                    </SnackbarProvider>
                </ErrorBoundary>
            </ErrorBoundaryContext>
    );
};
