import { Redirect } from 'react-router-dom';

import useSessionStore from 'src/stores/session';
import { ROUTES_URL } from 'src/constants/routes';
import useUserStore from 'src/stores/user';

export default function MainRoute() {
    const isAuthorized = useSessionStore(({ isAuthorized }) => isAuthorized);
    const isOnboardingCompleted = useUserStore(({ isOnboardingCompleted }) => isOnboardingCompleted);

    if (!isAuthorized) return <Redirect to={ROUTES_URL.LOGIN} />;

    if (!isOnboardingCompleted) return <Redirect to={ROUTES_URL.BEGIN_WORK} />;

    return <Redirect to={ROUTES_URL.FEED} />;
}
