import { updateBeginWorkStep } from 'src/providers/UpdateBeginWorkStepsProvider';
import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';

import useGoTo from 'src/hooks/useGoTo';

import { ROUTES_URL } from 'src/constants/routes';

import Keywords from '../Keywords';

export default function BeginWorkKeywords() {
    const goTo = useGoTo();

    const onSuccessHandler = () => {
        updateBeginWorkStep({
            currentStep: BEGIN_WORK_STEPS_IDS.ADD_KEYWORDS,
            onSuccess: () => {
                goTo(ROUTES_URL.BEGIN_WORK_CREATE_PROJECT);
            },
        });
    };

    return <Keywords onSuccess={onSuccessHandler} />;
}
