export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.43597 5.18404C2 6.03969 2 7.15979 2 9.4V12.4C2 10.1598 2 9.03968 2.43597 8.18404C2.81947 7.43139 3.43139 6.81947 4.18404 6.43597C5.03969 6 6.15979 6 8.4 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C22 9.03968 22 10.1598 22 12.4V9.4C22 7.15979 22 6.03969 21.564 5.18404C21.1805 4.43139 20.5686 3.81947 19.816 3.43597C18.9603 3 17.8402 3 15.6 3H8.4C6.15979 3 5.03969 3 4.18404 3.43597C3.43139 3.81947 2.81947 4.43139 2.43597 5.18404Z"
            fill="#E3ECF3"
        />
        <path
            d="M2.5 12.4C2.5 11.2716 2.50039 10.4554 2.55284 9.81352C2.60481 9.17744 2.70539 8.75662 2.88148 8.41103C3.21703 7.75247 3.75247 7.21703 4.41103 6.88148C4.75662 6.70539 5.17744 6.60481 5.81352 6.55284C6.45545 6.50039 7.27164 6.5 8.4 6.5H15.6C16.7284 6.5 17.5446 6.50039 18.1865 6.55284C18.8226 6.60481 19.2434 6.70539 19.589 6.88148C20.2475 7.21703 20.783 7.75247 21.1185 8.41103C21.2946 8.75662 21.3952 9.17744 21.4472 9.81352C21.4996 10.4554 21.5 11.2716 21.5 12.4V14.6C21.5 15.7284 21.4996 16.5446 21.4472 17.1865C21.3952 17.8226 21.2946 18.2434 21.1185 18.589C20.783 19.2475 20.2475 19.783 19.589 20.1185C19.2434 20.2946 18.8226 20.3952 18.1865 20.4472C17.5446 20.4996 16.7284 20.5 15.6 20.5H8.4C7.27164 20.5 6.45545 20.4996 5.81352 20.4472C5.17744 20.3952 4.75662 20.2946 4.41103 20.1185C3.75247 19.783 3.21703 19.2475 2.88148 18.589C2.70539 18.2434 2.60481 17.8226 2.55284 17.1865C2.50039 16.5446 2.5 15.7284 2.5 14.6V12.4Z"
            stroke="#1F1F1F"
        />
        <rect
            x="16"
            y="12"
            width="3"
            height="3"
            rx="1.5"
            fill="#1F1F1F"
        />
    </svg>
);
