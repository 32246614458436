import { Box } from '@mui/material';
import useIsMobileSize from 'src/hooks/useIsMobileSize';

import type { ReactNode } from 'react';

type PublicLayoutProps = {
    children: ReactNode;
};

export default ({ children }: PublicLayoutProps) => {
    const isMobile = useIsMobileSize();

    if (!isMobile) return <>{children}</>;

    return <Box mt={4.5}>{children}</Box>;
};
