import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Checkbox } from '@mui/material';
import MapLayer from 'src/icons/MapLayer';
import Peoples from 'src/icons/Peoples';

import { getWordWithNumber } from 'src/utils';

import { useFormContext } from 'react-hook-form';

import type { ICollectionsChatBundles } from 'src/types/Collections';

type CollectionsListProps = {
    list: ICollectionsChatBundles;
    isDisabled?: boolean;
};

const EmptyList = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={10}
        >
            <Box mb={1}>
                <MapLayer />
            </Box>
            <Box mb={0.5}>
                <Typography
                    variant="body1"
                    color="primary"
                    textAlign="center"
                >
                    Подборки не найдены
                </Typography>
            </Box>
            <Box>
                <Typography
                    variant="body2"
                    color="secondary"
                    textAlign="center"
                >
                    Попробуйте изменить запрос или выбрать другой фильтр
                </Typography>
            </Box>
        </Box>
    );
};

export default function CollectionsList({ list, isDisabled }: CollectionsListProps) {
    const { register, watch } = useFormContext();
    const collections: string[] = watch('collections');

    if (list.length < 1) {
        return <EmptyList />;
    }

    return (
        <List
            sx={{
                width: '100%',
                background: '#fff',
                border: '1px solid',
                borderColor: '#E3ECF3',
                display: 'block',
                borderRadius: '16px',
            }}
            disablePadding
        >
            {list.map(({ name, city, chat_count, id }, idx) => {
                const checked = collections.includes(String(id));
                return (
                    <label
                        key={id}
                        htmlFor={String(id)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <ListItem
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    color="primary"
                                    id={String(id)}
                                    disabled={checked ? false : isDisabled}
                                    value={String(id)}
                                    checked={checked}
                                    {...register('collections')}
                                />
                            }
                            divider={idx !== list.length - 1}
                        >
                            <ListItemIcon>
                                <MapLayer />
                            </ListItemIcon>
                            <ListItemText
                                primary={name}
                                secondary={
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        {city && (
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {city}
                                            </Typography>
                                        )}

                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Peoples />
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {getWordWithNumber(chat_count, ['чат', 'чата', 'чатов'])}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            />
                        </ListItem>
                    </label>
                );
            })}
        </List>
    );
}
