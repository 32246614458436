import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Chip, Collapse, Drawer, IconButton, Typography } from '@mui/material';
import hexContrastColor from 'hex-contrast-color';

import useProjectsStore from 'src/stores/projects';
import useChatsStore from 'src/stores/chats';
import useIsMobileSize from 'src/hooks/useIsMobileSize';
import EventService from 'src/services/EventService';
import ArrowLeft from 'src/icons/ArrowLeft';
import FormWrapper from 'src/components/FormWrapper';
import CustomStepperItem from 'src/components/CustomStepperItem';
import ROUTES from 'src/router/routes';
import Wallet from 'src/icons/Wallet';
import useUserStore from 'src/stores/user';
import { history } from 'src/utils/history';
import Logo from 'src/components/Logo';

import MenuButton from './components/MenuButton';
import Book from './Icons/Book';
import Chats from './Icons/Chats';
import MapLayer from './Icons/MapLayer';
import ItemMenu from './components/ItemMenu';
import AccordeonMenu, { AccordeonButton, AccordeonMenuContainer } from './components/AccordeonMenu';
import menuList, { PAGES } from './menuList';
import BottomIcons from './Icons/BottomIcons';
import Bolt from './Icons/Bolt';
import useCollectionsStore from 'src/stores/collections';


type MenuProps = {
    open: boolean;
    onClose: () => void;
    anchore: 'right' | 'left';
    variant: 'temporary' | 'permanent';
};

const MenuComponent = ({ open, onClose, anchore, variant }: MenuProps) => {
    const isMobile = useIsMobileSize();
    const [collapse, setCollapse] = useState(true);
    
    const { activeProject } = useProjectsStore(({ activeProject }) => ({ activeProject }));
    const { privateCollection } = useCollectionsStore(({ privateCollection }) => ({ privateCollection }));
    const { bonusPoints, current_subscription, isOnboardingCompleted } = useUserStore(
        ({ bonusPoints, current_subscription, isOnboardingCompleted,  }) => ({
            bonusPoints,
            current_subscription,
            isOnboardingCompleted,
        })
    );

    const bundles = activeProject?.bundles.filter((collection) => collection.is_public);

    const renderButtonsPage = () => {
        return (
            <>
                <Box
                    mx={-1}
                    mb={1}
                >
                    <CustomStepperItem
                        showStep={false}
                        step={0}
                        currentStep={0}
                        onClick={() => {
                            history.push(ROUTES.MY_TARIFF.path);
                            EventService.dispatch('CLOSE_MENU');
                        }}
                        title="Ваш тариф"
                        chip={
                            <Typography
                                onClick={onClose}
                                component={RouterLink}
                                variant="body2"
                                color="info"
                                style={{
                                    textDecoration: 'none',
                                }}
                                to={ROUTES.MY_TARIFF.path}
                            >
                                {current_subscription && (
                                    <Chip
                                        label={current_subscription.plan.name}
                                        size="medium"
                                        style={{
                                            backgroundColor: '#' + current_subscription.plan.color_code,
                                            color: hexContrastColor('#' + current_subscription.plan.color_code),
                                        }}
                                    />
                                )}
                                {!current_subscription && 'Выбрать тариф'}
                            </Typography>
                        }
                        done
                        sx={{
                            marginLeft: '6px',
                            marginRight: '6px',
                        }}
                    />
                </Box>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                >
                    <MenuButton
                        label="Словарь"
                        subLabel={`${activeProject?.keywords?.length || 0} из ${current_subscription?.plan.keywords_limit || 0}`}
                        icon={<Book />}
                        onClick={() => {
                            history.push(ROUTES.KEYWORDS.path);
                            EventService.dispatch('CLOSE_MENU');
                        }}
                        disabled={!isOnboardingCompleted}
                    />
                    <MenuButton
                        label="Чаты"
                        subLabel={`${privateCollection?.chats?.length || 0} из ${current_subscription?.plan.chats_limit || 0}`}
                        icon={<Chats />}
                        onClick={() => {
                            history.push(ROUTES.CHATS_AND_CHANNELS.path);
                            EventService.dispatch('CLOSE_MENU');
                        }}
                        disabled={!isOnboardingCompleted}
                    />
                    <MenuButton
                        label="Подборки"
                        subLabel={`${bundles?.length || 0} из ${current_subscription?.plan.bundles_limit || 0}`}
                        icon={<MapLayer />}
                        onClick={() => {
                            history.push(ROUTES.COLLECTIONS.path);
                            EventService.dispatch('CLOSE_MENU');
                        }}
                        disabled={!isOnboardingCompleted}
                    />
                    <MenuButton
                        label="Бустер"
                        subLabel="Скоро"
                        icon={<Bolt />}
                        onClick={() => { }}
                        disabled={true}
                    />
                </Box>
            </>
        );
    };

    const renderButtonsPageWrapper = () => {
        if (isMobile) {
            return (
                <FormWrapper
                    borderRadius={4}
                    sx={{
                        padding: '6px',
                    }}
                >
                    {renderButtonsPage()}
                </FormWrapper>
            );
        }

        return (
            <Box
                border="1px solid #E3ECF3"
                borderRadius={4}
                padding={0.8}
            >
                {renderButtonsPage()}
            </Box>
        );
    };

    return (
        <Drawer
            anchor={anchore}
            open={open}
            onClose={onClose}
            variant={variant}
            PaperProps={{
                sx: {
                    position: 'sticky',
                    height: '100dvh',
                    overflow: 'auto',
                    width: '100%',
                },
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                width={isMobile ? '100vw' : '100%'}
                height={'100%'}
                overflow="auto"
                bgcolor={isMobile ? 'background.paper' : 'background.default'}
                px={1}
            >
                <Box
                    height={40}
                    display="flex"
                    alignItems="center"
                >
                    {isMobile && (
                        <IconButton onClick={onClose}>
                            <ArrowLeft />
                        </IconButton>
                    )}
                    {!isMobile && (
                        <Box my={1}>
                            <Logo />
                        </Box>
                    )}
                </Box>
                {!isMobile && (
                    <Box mb={20/8} mt={1.5}>
                        <AccordeonMenuContainer>
                            {PAGES.map((page, key) => (
                                <ItemMenu
                                    key={key}
                                    title={page.title}
                                    icon={page.icon}
                                    onAction={page.onClick}
                                />
                            ))}
                        </AccordeonMenuContainer>
                    </Box>
                )}
                <Box
                    onClick={() => {
                        if (isMobile) return null;

                        setCollapse((old) => !old);
                    }}
                    style={{
                        cursor: 'pointer',
                    }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={1}
                    mb={isMobile ? 20 / 8 : 1}
                >
                    <Typography variant={isMobile ? 'h1' : 'h3'}>Меню</Typography>
                    {!isMobile && <AccordeonButton isOpen={collapse} />}
                </Box>
                <Collapse
                    in={isMobile ? true : collapse}
                    sx={{
                        minHeight: 'auto!important',
                    }}
                >
                    <Box mb={1}>{renderButtonsPageWrapper()}</Box>
                    <AccordeonMenuContainer mb={1}>
                        <ItemMenu
                            icon={<Wallet />}
                            title="Бонусы"
                            customActionComponent={
                                <Typography
                                    variant="body2"
                                    color="secondary"
                                >
                                    {bonusPoints}
                                </Typography>
                            }
                            action
                        />
                    </AccordeonMenuContainer>
                    <Box>
                        <AccordeonMenu
                            items={menuList}
                            isMobile={isMobile}
                        />
                    </Box>
                </Collapse>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={isMobile ? 'center' : 'flex-start'}
                    justifyContent="flex-end"
                    gap={1}
                    pb={3}
                    mt={2}
                >
                    <Typography
                        textAlign="center"
                        variant="body2"
                        color="secondary"
                    >
                        2024 © ТГХ, Версия 1.01.20 от 20.09.24
                    </Typography>
                    <BottomIcons />
                </Box>
            </Box>
        </Drawer>
    );
};

export default MenuComponent;
