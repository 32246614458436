import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import { TCreateTProject, TProject, TProjects, TUpdateTProject } from 'src/types/Projects';
import ProjectsService from 'src/services/ProjectsService';

interface IState {
    projects: TProjects;

    activeProject: TProject | null;
}

interface IActions {
    setProjects: (projects: TProjects) => void;

    createProject: (project?: TCreateTProject) => Promise<void>;

    udapteProject: (project: TUpdateTProject) => Promise<void>;

    setActiveProject: (arg: TProject) => void;

    setActiveProjectById: (id: number) => void;
    clear: () => void;
}

const useProjectsStore = create<IState & IActions>(
    (set, get) => ({
        projects: [],

        activeProject: null,
        clear: () => set({ projects: [], activeProject: null }),
        setActiveProject: (project) => {
            set({ activeProject: project });
        },
        setActiveProjectById: (activeProjectId) => {
            const { projects } = get();
            const activeProject = projects.find(({ id }) => id === activeProjectId);
            if (activeProject) {
                set({ activeProject });
            }
        },

        setProjects: (projects) => {
            const { activeProject, setActiveProject, setActiveProjectById } = get();
            set({ projects });

            if (!activeProject?.id && projects.length > 0) {
                setActiveProject(projects[0]);
            } else if (activeProject?.id) {
                setActiveProjectById(activeProject.id);
            }
        },

        createProject: async (
            { name = 'Мой проект', ...project } = { name: 'Мой проект', keywords: [], bundles: [] }
        ) => {
            try {
                const data = await ProjectsService.createProject({ name, ...project });
                const { projects } = get();

                set({ projects: [...projects, data] });

                return Promise.resolve();
            } catch (err) {
                return Promise.reject(err);
            }
        },

        udapteProject: async (project) => {
            try {
                const { projects, setProjects, activeProject } = get();

                const data = await ProjectsService.updateProject({ id: activeProject?.id, ...project });

                const newProjects = projects.map((project) => {
                    if (project.id === data.id) {
                        return data;
                    }
                    return project;
                });

                setProjects(newProjects);

                return Promise.resolve();
            } catch (err) {
                return Promise.reject(err);
            }
        },
    }),
    shallow
);

export default useProjectsStore;
