import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import { Controller } from 'react-hook-form';
import SubscriptionsService from 'src/services/SubscriptionsService';
import useSubscriptionsStore from 'src/stores/subscriptions';
import BonusIcon from 'src/icons/BonusIcon';

const TicketFrame = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="48"
        preserveAspectRatio="none"
        viewBox="0 0 369 48"
        fill="none"
    >
        <path
            d="M24 1V47H3.97555C2.33489 47 1 45.6623 1 44.0162C1 43.0709 1.45271 42.18 2.21538 41.6253C4.73189 39.7951 4.95631 36.1247 2.68152 34.0015L2.26175 33.6097C0.779058 32.2259 0.81697 29.8639 2.34331 28.5284L2.59387 28.3091C5.201 26.0279 5.201 21.9721 2.59387 19.6909L2.34331 19.4716C0.816971 18.1361 0.779058 15.7741 2.26174 14.3903L2.68152 13.9985C4.95631 11.8753 4.73189 8.20488 2.21538 6.37469C1.45271 5.82002 1 4.92913 1 3.98379C1 2.33774 2.33489 1 3.97555 1H24Z"
            fill="white"
            stroke="#1F1F1F"
            strokeWidth="2"
        />
        <path
            d="M345 47L345 0.999998L365.024 0.999999C366.665 1 368 2.33774 368 3.98379C368 4.92913 367.547 5.82002 366.785 6.37469C364.268 8.20488 364.044 11.8753 366.318 13.9985L366.738 14.3903L367.403 13.678L366.738 14.3903C368.221 15.7741 368.183 18.1361 366.657 19.4716L366.406 19.6909C363.799 21.9721 363.799 26.0279 366.406 28.3091L366.657 28.5284C368.183 29.8639 368.221 32.2259 366.738 33.6097L366.318 34.0015C364.044 36.1247 364.268 39.7951 366.785 41.6253C367.547 42.18 368 43.0709 368 44.0162C368 45.6623 366.665 47 365.024 47L345 47Z"
            fill="white"
            stroke="#1F1F1F"
            strokeWidth="2"
        />
        <mask
            id="path-3-inside-1_3759_9167"
            fill="white"
        >
            <path d="M23 0H346V48H23V0Z" />
        </mask>
        <path
            d="M23 0H346V48H23V0Z"
            fill="white"
        />
        <path
            className="mask_center"
            id="path_center"
            d="M23 2H346V-2H23V2ZM346 46H23V50H346V46Z"
            fill="#1F1F1F"
            mask="url(#path-3-inside-1_3759_9167)"
        />
    </svg>
);

const StyledInputContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '2px',
    bottom: '2px',
    left: '16px',
    right: '35px',
    height: '44px',
    width: 'calc(100% - 35px)',
}));
const StyledInput = styled((props: any) => <input {...props} />)(({ theme }) => ({
    height: '44px',
    width: 120,
    border: 'none',
    outline: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    textTransform: 'uppercase',
}));

const StyledTicketFrame = styled((props: any) => (
    <Box {...props}>
        <TicketFrame {...props} />
    </Box>
))(({ focus, theme }) => ({
    '& svg>path': {
        color: focus ? '#FAEB66' : '#fff',
        stroke: focus ? '#FAEB66' : '#fff',
    },
    '& svg>.mask_center': {
        fill: focus ? '#FAEB66' : '#fff',
    },
    [theme.breakpoints.down('md')]: {
        '& svg>path': {
            color: focus ? '#FAEB66' : '#000',
            stroke: focus ? '#FAEB66' : '#000',
        },
        '& svg>.mask_center': {
            fill: focus ? '#FAEB66' : '#000',
        },
    },
}));

const StyledContainer = styled(({ children, success = false, ...otherProps }: any) => (
    <Box {...otherProps}>{children}</Box>
))((props) => ({
    position: 'relative',
    height: 48,
    width: '100%',
    '&': {
        boxShadow: props.success
            ? '0px 8px 6px -6px rgba(250, 255, 0, 0.60), 0px -8px 6px -6px rgba(250, 255, 0, 0.60)'
            : 'none',
    },
    '&:focus-within': {
        boxShadow: '0px 8px 6px -6px rgba(250, 255, 0, 0.60), 0px -8px 6px -6px rgba(250, 255, 0, 0.60)',
    },
    '& > svg>path': {
        color: props.success ? '#FAEB66' : 'inherich',
        stroke: props.success ? '#FAEB66' : 'inherich',
    },
    '& > svg>.mask_center': {
        fill: props.success ? '#FAEB66' : 'inherich',
    },
}));

type TicketFieldProps = {
    control: any;
    name: string;
    placeholder?: string;
};

const TicketField = ({ control, name, placeholder = '' }: TicketFieldProps) => {
    const [showIcon, setShowIcon] = useState(false);
    const [focus, setFocus] = useState(false);
    const { setPromoSale } = useSubscriptionsStore(({ setPromoSale }) => ({ setPromoSale }));

    useEffect(() => {
        return () => {
            setPromoSale(0);
        };
    }, []);

    const onBlur = (value) => {
        if (value) {
            SubscriptionsService.checkPromocode(value.toUpperCase())
                .then((data) => {
                    setPromoSale(data.discount_percent);
                    enqueueSnackbar({
                        message: data.message,
                        variant: 'success',
                    });
                    setShowIcon(true);
                })
                .catch((error) => {
                    setPromoSale(0);
                    enqueueSnackbar({
                        message: error?.error || 'Код не подходит',
                        variant: 'error',
                    });
                    setShowIcon(false);
                });
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <StyledContainer success={showIcon}>
                    <StyledInputContainer bgcolor="#FFF">
                        {showIcon && <BonusIcon />}
                        <StyledInput
                            {...field}
                            onFocus={() => setFocus(true)}
                            onBlur={() => {
                                onBlur(field.value);
                                setFocus(false);
                                field.onBlur();
                            }}
                            placeholder={placeholder}
                        />
                    </StyledInputContainer>
                    <StyledTicketFrame focus={focus || showIcon} />
                </StyledContainer>
            )}
        />
    );
};

export default TicketField;
