import globalAgent from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { AxiosPromise } from 'axios';
import type {
    ICollectionsChatBundle,
    ICollectionsChatBundleRequest,
    ICollectionsItemResponse,
    IGetCollectionsRequestParams,
} from 'src/types/Collections';

export const getCollections = ({
    city,
    tags,
    is_public,
    project_id,
}: Partial<IGetCollectionsRequestParams>): AxiosPromise<ICollectionsItemResponse> => {
    const currentTags = tags && tags.length > 0 ? tags.join(',') : undefined;
    const currentCity = city || undefined;
    return globalAgent.get(URLS.BUNDLES, {
        params: {
            tags: currentTags,
            city: currentCity,
            is_public,
            project_id,
        },
    });
};

export const createCollection = (data: ICollectionsChatBundleRequest): AxiosPromise<ICollectionsChatBundle> => {
    return globalAgent.post(URLS.BUNDLES_CREATE, data);
};

export const updateCollection = ({
    id,
    ...data
}: ICollectionsChatBundleRequest): AxiosPromise<ICollectionsChatBundle> => {
    return globalAgent.patch(`${URLS.BUNDLES}${id}/`, data);
};
