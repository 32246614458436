import { Box, Button, debounce, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import CustomInputField from 'src/components/CustomInputField';
import LoadingWrapper from 'src/components/Loading/LoadingWrapper';
import useSearchParams from 'src/hooks/useSearchParams';
import CollectionService from 'src/services/CollectionService';
import useCollectionsStore from 'src/stores/collections';
import useProjectsStore from 'src/stores/projects';
import useUserStore from 'src/stores/user';

import CollectionsFilter from 'src/widgets/CollectionsFilter';
import CollectionsList from 'src/widgets/CollectionsList';
import TitleWithCounter from 'src/widgets/TitleWithCounter';

import { getBeginWorkStepByOrder } from 'src/utils/beginWork';

import type { IBeginWorkOnSuccessArgs } from 'src/types';

interface IColelctionsFormValues {
    collections: string[];
}

export interface ICollectionProps {
    onSuccess?: ({ skiped }: IBeginWorkOnSuccessArgs) => void;
}

export default function Collection({ onSuccess }: ICollectionProps) {
    const { cities, privateCollection } = useCollectionsStore(({ publicCollections, cities, privateCollection }) => ({
        cities,
        privateCollection,
    }));

    const { currentSubscriptionPlan, onboardingStep } = useUserStore(({ current_subscription, onboardingStep }) => ({
        currentSubscriptionPlan: current_subscription?.plan,
        onboardingStep,
    }));

    const activeProject = useProjectsStore(({ activeProject }) => activeProject);

    const {
        params: { city = '', search = '' },
        setParams,
    } = useSearchParams();

    const methods = useForm<IColelctionsFormValues>({
        defaultValues: {
            collections: [],
        },
    });

    const tags = search.split(' ').filter(Boolean);
    const isEmptyFilters = tags.length < 1 && !city;

    const { watch, setValue } = methods;
    const selectedCollections = watch('collections');

    const currentCount = selectedCollections.length;
    const totalCount = currentSubscriptionPlan?.bundles_limit || 0;

    const hasSkipAbility = getBeginWorkStepByOrder(onboardingStep)?.id === 'choose_collections';

    const {
        data: { chat_bundles: publicCollections = [] } = {},
        isLoading: isGetCollectionsLoading,
        isError: isGetCollectionsError,
    } = useQuery({
        queryKey: [CollectionService.queryKey, city, tags],
        queryFn: () => CollectionService.getPublicCollections(city, tags),
        onError: () => {
            enqueueSnackbar({ message: 'Ошибка получения подборок', variant: 'error' });
        },
    });

    useEffect(() => {
        const defaultValues = activeProject?.bundles.reduce((acc, projectBundle) => {
            const colelction = publicCollections?.find(({ id: colelctionId }) => colelctionId === projectBundle.id);
            if (colelction?.id) {
                acc.push(String(colelction.id));
            }

            return acc;
        }, [] as string[]);

        if (defaultValues) {
            setValue('collections', defaultValues);
        }
    }, [activeProject, publicCollections]);

    const { mutate: udapteProject, isLoading: isUdapteProjectLoading } = useMutation(
        useProjectsStore.getState().udapteProject,
        {
            onSuccess: () => {
                onSuccess?.({ skiped: false });
                enqueueSnackbar('Подборки сохранены', { variant: 'success' });
            },

            onError: (error) => {
                console.log(error);
                enqueueSnackbar({ message: 'Ошибка сохранения подборок', variant: 'error' });
            },
        }
    );

    const onSubmit = ({ collections }: IColelctionsFormValues) => {
        if (hasSkipAbility && !currentCount) {
            onSuccess?.({ skiped: true });
            return;
        }

        udapteProject({
            id: activeProject?.id,
            bundles: [privateCollection.id, ...collections.map((id) => Number(id))],
        });
    };

    const [searchValue, setSearchValue] = useState(search);

    const onSearchChange = (value: string) => {
        setSearchValue(value);
        onFilteredCitiesChange(value);
    };

    const onFilterTagsChangeHandler = (value: string) => {
        setParams({ search: value });
    };

    const isMaxCount = currentCount >= totalCount;
    const onFilteredCitiesChange = useCallback(debounce(onFilterTagsChangeHandler, 500), []);

    if (isGetCollectionsError) return null;

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ minHeight: '100%', display: 'grid' }}
            >
                <Box
                    display="flex"
                    minHeight="100%"
                    flexDirection="column"
                    pt={2}
                >
                    <Box mb={1.5}>
                        <TitleWithCounter
                            title="Подборки"
                            current={currentCount}
                            total={totalCount}
                        />
                    </Box>
                    <Box
                        maxWidth="80%"
                        mb={20 / 8}
                    >
                        <Typography
                            variant="body1"
                            color="primary"
                        >
                            Готовые пакеты тематических чатов
                        </Typography>
                    </Box>
                    <LoadingWrapper
                        isLoading={isGetCollectionsLoading || isUdapteProjectLoading}
                        isForced
                    >
                        <Box mb={1.5}>
                            <CustomInputField
                                placeholder="Искать по тематике или услуге"
                                value={searchValue}
                                onChange={onSearchChange}
                                name="search"
                                disabled={!totalCount}
                            />
                        </Box>
                        <Box mb={1.5}>
                            <CollectionsFilter
                                cities={cities}
                                isDisabled={!totalCount}
                                // filters={[
                                //     {
                                //         id: 1,
                                //         name: 'Котики',
                                //         selected: false,
                                //     },
                                //     {
                                //         id: 2,
                                //         name: 'ПИК-Комфорт',
                                //         selected: true,
                                //     },
                                // ]}
                            />
                        </Box>
                        <Box mb={1.5}>
                            <CollectionsList
                                list={publicCollections}
                                isDisabled={!totalCount || isMaxCount}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="flex-end"
                            flexGrow={1}
                            mb={1}
                        >
                            <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                type="submit"
                            >
                                {hasSkipAbility ? (currentCount ? 'Продолжить' : 'Пропустить') : 'Сохранить'}
                            </Button>
                        </Box>
                    </LoadingWrapper>
                </Box>
            </form>
        </FormProvider>
    );
}
