export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
    >
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.5 44C40.4706 44 44.5 39.9706 44.5 35C44.5 30.0294 40.4706 26 35.5 26C30.5294 26 26.5 30.0294 26.5 35C26.5 39.9706 30.5294 44 35.5 44ZM31.7929 31.2929C32.1834 30.9024 32.8166 30.9024 33.2071 31.2929L35.5 33.5858L37.7929 31.2929C38.1834 30.9024 38.8166 30.9024 39.2071 31.2929C39.5976 31.6834 39.5976 32.3166 39.2071 32.7071L36.9142 35L39.2071 37.2929C39.5976 37.6834 39.5976 38.3166 39.2071 38.7071C38.8166 39.0976 38.1834 39.0976 37.7929 38.7071L35.5 36.4142L33.2071 38.7071C32.8166 39.0976 32.1834 39.0976 31.7929 38.7071C31.4024 38.3166 31.4024 37.6834 31.7929 37.2929L34.0858 35L31.7929 32.7071C31.4024 32.3166 31.4024 31.6834 31.7929 31.2929Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.11531 17.6152C16.2689 7.46159 32.7312 7.46159 42.8849 17.6152C43.2754 18.0057 43.9086 18.0057 44.2991 17.6152C44.6896 17.2247 44.6896 16.5915 44.2991 16.201C33.3644 5.26633 15.6358 5.26633 4.7011 16.201C4.31057 16.5915 4.31057 17.2247 4.7011 17.6152C5.09162 18.0057 5.72479 18.0057 6.11531 17.6152ZM24.5001 37.9998C23.3955 37.9998 22.5001 37.1044 22.5001 35.9998C22.5001 34.8953 23.3955 33.9998 24.5001 33.9998C24.515 33.9998 24.5299 34 24.5448 34.0003C24.606 33.3206 24.7291 32.6588 24.9083 32.0204C24.7741 32.0068 24.6379 31.9998 24.5001 31.9998C22.2909 31.9998 20.5001 33.7907 20.5001 35.9998C20.5001 38.209 22.2909 39.9998 24.5001 39.9998C24.8888 39.9998 25.2645 39.9444 25.6198 39.841C25.3259 39.2423 25.0849 38.6131 24.9027 37.9593C24.7726 37.9859 24.638 37.9998 24.5001 37.9998ZM35.0925 24.0073C28.8087 18.4464 19.1997 18.6726 13.1864 24.6859C12.7959 25.0765 12.7959 25.7096 13.1864 26.1002C13.5769 26.4907 14.2101 26.4907 14.6006 26.1002C19.4438 21.257 26.9526 20.7041 32.4044 24.4415C33.2617 24.1905 34.1624 24.0412 35.0925 24.0073ZM28.2499 26.727C27.0918 26.2582 25.8258 25.9998 24.4999 25.9998C21.7481 25.9998 19.2542 27.1126 17.4471 28.9104C17.0556 29.3 17.054 29.9331 17.4435 30.3247C17.833 30.7162 18.4662 30.7178 18.8577 30.3283C20.3053 28.8881 22.2979 27.9998 24.4999 27.9998C25.2841 27.9998 26.0418 28.1125 26.7577 28.3227C27.2014 27.7426 27.7016 27.208 28.2499 26.727ZM24.5001 15.9998C18.9036 15.9998 13.8455 18.2972 10.2143 22.0028C9.82778 22.3972 9.19465 22.4037 8.80019 22.0171C8.40572 21.6306 8.3993 20.9974 8.78584 20.603C12.7777 16.5293 18.3444 13.9998 24.5001 13.9998C30.6558 13.9998 36.2224 16.5293 40.2143 20.603C40.6009 20.9974 40.5944 21.6306 40.2 22.0171C39.8055 22.4037 39.1724 22.3972 38.7858 22.0028C35.1546 18.2972 30.0965 15.9998 24.5001 15.9998Z"
                fill="#9FA9BC"
            />
        </g>
    </svg>
);
