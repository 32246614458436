export function matchIsNumeric(value: unknown) {
    const isNumber = typeof value === 'number';
    const isValidString = typeof value === 'string' && value.trim() !== '';
    return isNumber || (isValidString && !isNaN(Number(value)));
}

export function getWordWithNumber(value: number, words: [string, string, string]) {
    const currentValue = Math.abs(value) % 100;
    const num = currentValue % 10;
    let index = 2;
    if (num === 1) {
        index = 0;
    } else if (num > 1 && num < 5) {
        index = 1;
    }

    return `${value} ${words[index]}`;
}
