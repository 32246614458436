export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M3.5 9.5L7.29289 5.70711C7.68342 5.31658 8.31658 5.31658 8.70711 5.70711L12.5 9.5"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
