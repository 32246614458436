import { Box } from '@mui/material';
import ChangePasswordForm from 'src/widgets/ChangePassword';
import { WithoutHeader } from 'src/layout';
import { history } from 'src/utils/history';
import Title from 'src/components/Title';

export default function ChangePassword() {
    const handleBack = () => {
        history.goBack();
    };

    return (
        <WithoutHeader showClose>
            <Box px={2}>
                <Box mb={3}>
                    <Title>Изменение пароля</Title>
                </Box>
                <ChangePasswordForm />
            </Box>
        </WithoutHeader>
    );
}
