import { fetchChats } from "./agent";

class ChatsService {
    fetch = async () => {
        try {
            const { data } = await fetchChats();

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default new ChatsService