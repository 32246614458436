import globalAgent, { createAxiosAgent } from 'src/agent';
import { URLS } from 'src/constants/urls';

import type { AxiosPromise, CancelToken } from 'axios';
import type { IUserResponseBase, IUserSignIn, IUpdateToken, IUserRegister } from 'src/types/User';

const agent = createAxiosAgent();

export const signIn = (values: IUserSignIn): AxiosPromise<IUserResponseBase> => agent.post(URLS.SIGN_IN, values);

export const signUp = (values: IUserRegister): AxiosPromise<unknown> => agent.post(URLS.REGISTER, values);

export const updateToken = (refreshToken: string): AxiosPromise<IUpdateToken> =>
    agent.post(URLS.REFRESH, {
        refresh: refreshToken,
    });

export const getCode = (values: Pick<IUserSignIn, 'email'>, cancelToken: CancelToken) =>
    globalAgent.post(URLS.PASSWORD_RESET, values, { cancelToken });

export const confirmResetPassword = (
    values: { code: string; new_password: string; new_password2: string } & Pick<IUserRegister, 'email'>
) => globalAgent.post(URLS.PASSWORD_CONFIRM_RESET, values);

export const confirmEmail = (values: Pick<IUserSignIn, 'email'> & { code: string }) =>
    globalAgent.post(URLS.CONFIRM_EMAIL, values);

export const refreshEmailCode = () => globalAgent.post(URLS.REFETCH_EMAIL_CODE);
