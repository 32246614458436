import { updateBeginWorkStep } from 'src/providers/UpdateBeginWorkStepsProvider';
import { BEGIN_WORK_STEPS_IDS } from 'src/constants/beginWorksSteps';

import useGoTo from 'src/hooks/useGoTo';

import { ROUTES_URL } from 'src/constants/routes';

import CreateProject from '../CreateProject';

export default function BeginWorkCreateProject() {
    const goTo = useGoTo();

    const onSuccessHandler = () => {
        updateBeginWorkStep({
            currentStep: BEGIN_WORK_STEPS_IDS.CREATE_PROJECT,
            onSuccess: () => {
                goTo(ROUTES_URL.BEGIN_WORK_CREATE_PROJECT_FINISH);
            },
        });
    };

    return <CreateProject onSuccess={onSuccessHandler} />;
}
