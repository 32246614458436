import { Box, IconButton, Typography } from '@mui/material';
import Cross from 'src/icons/Cross';
import { closeBottomSheet } from 'src/components/BottomSheet';

type BottomSheetHeaderProps = {
    title: string;
};

const BottomSheetHeader = ({ title }: BottomSheetHeaderProps) => {
    const handleClose = () => {
        closeBottomSheet();
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography
                variant="h2"
                color="primary"
            >
                {title}
            </Typography>
            <IconButton onClick={handleClose}>
                <Cross />
            </IconButton>
        </Box>
    );
};

export default BottomSheetHeader;
