import { Box, Typography, styled } from '@mui/material';

import type { ReactNode } from 'react';

const Container = styled('button')(({ disabled, theme }) => ({
    flexBasis: '48%',
    flexShrink: 0,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    gap: 1.5,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    border: 'none',
    opacity: disabled ? 0.4 : 1,
    transition: 'ease-in',
    cursor: 'pointer',
    maxHeight: '64px',
    '&:hover': {
        opacity: disabled ? 0.4 : 0.7,
    },
}));

type MenuButtonProps = {
    label: string;
    onClick: () => void;
    subLabel?: string;
    disabled?: boolean;
    icon?: ReactNode;
};

const MenuButton = ({ label, onClick, disabled = false, subLabel = '', icon = null }: MenuButtonProps) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    return (
        <Container disabled={disabled} onClick={handleClick}>
            {icon && <Box pt={0.5} width="32px" height="100%">{icon}</Box>}
            <Box pt={0.5}>
                <Typography
                    textAlign="left"
                    variant="body1"
                    color="primary"
                >
                    {label}
                </Typography>
                {subLabel && (
                    <Typography
                        textAlign="left"
                        variant="body2"
                        color="secondary"
                    >
                        {subLabel}
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default MenuButton;
