export default () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="8"
            cy="8"
            r="6.5"
            stroke="#1F1F1F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.50004 5C5.2239 5 5.00004 5.22386 5.00004 5.5C5.00004 5.77614 5.2239 6 5.50004 6H9.29293L4.64648 10.6464L5.35359 11.3536L10 6.70711V10.5C10 10.7761 10.2239 11 10.5 11C10.7762 11 11 10.7761 11 10.5V6.5C11 5.67157 10.3285 5 9.50004 5H5.50004Z"
            fill="#1F1F1F"
        />
    </svg>
);
