import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

type TSetParamsArgs = Record<string, string>;

const getParams = () =>
    qs.parse(window.location.search, { ignoreQueryPrefix: true }) as Record<string, string | undefined>;

export default function useSearchParams() {
    const { pathname } = useLocation();
    const { replace } = useHistory();
    const params = getParams();

    const setParamsHandler = (searchParams: TSetParamsArgs) => {
        const newParams = qs.stringify(
            { ...getParams(), ...searchParams },
            { allowDots: true, filter: (_, value) => value || undefined }
        );

        replace({ pathname: pathname, search: newParams });
    };

    return { params, setParams: setParamsHandler };
}
