import { useRef } from 'react';
import { Box, Typography, styled } from '@mui/material';

type ChipWithCounterProps = {
    label: string;
    selected: boolean;
    onClick: (value: number) => void;
    value: number;
    counter?: number;
};

const ChipContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: '8px',
    height: 32,
    padding: '4px 6px 4px 16px',
    borderRadius: '16px',
    opacity: 1,
    flexShrink: 1,
}));

export const Counter = styled(Box)(() => ({
    fontFamily: 'Inter',
    fontSize: '14px',
    minWidth: 22,
    height: '22px',
    borderRadius: '12px',
    paddingLeft: '4px',
    paddingRight: '4px',
    textAlign: 'center',
    lineHight: '22px',
}));

const ChipWithCouner = ({ label, selected, onClick, value, counter = undefined }: ChipWithCounterProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const handleClick = () => {
        if (ref.current) {
            ref.current.scrollIntoView(true);
        }
        onClick(value);
    };

    return (
        <div ref={ref}>
            <ChipContainer
                bgcolor={selected ? 'primary.main' : 'none'}
                onClick={handleClick}
            >
                <Typography
                    variant="body1"
                    color={selected ? 'textSecondary' : 'textPrimary'}
                    noWrap
                >
                    {label}
                </Typography>
                {counter && (
                    <Counter
                        bgcolor={selected ? '#403F40' : '#EFF3F7'}
                        color={selected ? '#fff' : '#000'}
                    >
                        {counter}
                    </Counter>
                )}
            </ChipContainer>
        </div>
    );
};

export const ChipsContainer = styled(Box)(() => ({
    display: 'flex',
    gap: '10px',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}));

export default ChipWithCouner;
