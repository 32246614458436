import { updateOnboardingStep, getUser, changeEmail, changeEmailConfirm } from './agent';

import type { ChangeEmailConfirmRequest, StepsType } from 'src/types/User';

class UserService {
    queryKey = 'user';

    updateOnboardingStep = async (step: StepsType) => {
        try {
            const { data } = await updateOnboardingStep(step);

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    getUser = async () => {
        try {
            const { data } = await getUser();

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    changeEmail = async (data: Pick<ChangeEmailConfirmRequest, 'new_email'>) => {
        try {
            await changeEmail(data);

            return Promise.resolve({});
        } catch (error) {
            return Promise.reject(error);
        }
    };

    changeEmailConfirm = async (data: ChangeEmailConfirmRequest) => {
        try {
            await changeEmailConfirm(data);

            return Promise.resolve({});
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export default new UserService();
