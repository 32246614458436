export default () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3848_33840)">
            <g clipPath="url(#clip1_3848_33840)">
                <rect
                    x="5.5"
                    y="5.5"
                    width="21"
                    height="21"
                    rx="10.5"
                    stroke="#1F1F1F"
                />
            </g>
            <path
                d="M8.5 23.5L23.5 8.5"
                stroke="#1F1F1F"
            />
        </g>
        <defs>
            <clipPath id="clip0_3848_33840">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(4 4)"
                />
            </clipPath>
            <clipPath id="clip1_3848_33840">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(4 4)"
                />
            </clipPath>
        </defs>
    </svg>
);
