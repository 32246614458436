import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Counter } from 'src/components/ChipWithCounter';

import { useId } from 'react';

import Card from './Card';

import type { IMessage } from 'src/types/Message';

type Props = {
    list: IMessage[];
    groupDate: string;
};

const ArticlesGroup = ({ list = [], groupDate }: Props) => {
    const id = useId();

    const currentDay = dayjs();


    const getTitle = () => {
        if (currentDay.format('DD.MM.YYYY') === groupDate) {
            return 'Сегодня';
        }

        if (currentDay.subtract(1, 'day').format('DD.MM.YYYY') === groupDate) {
            return 'Вчера';
        }

        return dayjs(groupDate,'DD.MM.YYYY').format('DD MMMM');
    };

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={12 / 8}
            >
                <Typography variant="h2">{getTitle()}</Typography>
                <Counter
                    borderRadius={'50%'}
                    border="1px solid #E3ECF3"
                    color="#647991"
                >
                    {list.length}
                </Counter>
            </Box>
            {list.length === 0 && (
                <Box mb={20/8}>
                    <Typography
                        variant="body2"
                        color="secondary"
                    >
                        Ещё никто не писал
                    </Typography>
                </Box>
            )}
            {list.length > 0 &&
                list.map((item) => {
                    return (
                        <Box
                            key={`${id}_${item.id}`}
                            mb={1}
                        >
                            <Card body={item} />
                        </Box>
                    );
                })}
        </Box>
    );
};

export default ArticlesGroup;
