import { Box } from '@mui/material';

import Empty from 'src/components/Empty';

export default function NotFound404Screen() {
    return (
        <Box
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width="100%"
        >
            <Empty text="Страница не найдена" />
        </Box>
    );
}
