export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="24"
        viewBox="0 0 12 24"
        fill="none"
    >
        <g>
            <path
                d="M4.5 6.5L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L4.5 17.5"
                stroke="#9FA9BC"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
