import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import ButtonText from 'src/components/ButtonText';
import PasswordField from 'src/components/PasswordField';
import ROUTES from 'src/router/routes';
import CustomAccordeon from 'src/components/CustomAccordeon';

type ChangePasswordFormType = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
};

const ChangePasswordForm = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangePasswordFormType>({
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    });
    const history = useHistory();

    const onSubmit: SubmitHandler<ChangePasswordFormType> = (data) => {
        console.log(data);
    };

    const restorePasswordhandleClick = () => {
        history.push(ROUTES.RESTORE_PASSWORD.path);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}
        >
            <Box mb={2}>
                <Controller
                    name="oldPassword"
                    control={control}
                    render={({ field }) => (
                        <PasswordField
                            {...field}
                            label="Старый пароль"
                            placeholder="Старый пароль"
                            error={errors?.oldPassword?.message}
                        />
                    )}
                />
                <Box mb={0.5}>
                    <ButtonText
                        icon="INFO"
                        onClick={restorePasswordhandleClick}
                    >
                        Восстановить пароль
                    </ButtonText>
                </Box>
            </Box>
            <Box mb={2}>
                <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                        <PasswordField
                            {...field}
                            label="Новый пароль"
                            placeholder="Новый пароль"
                            error={errors?.newPassword?.message}
                        />
                    )}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                        <PasswordField
                            {...field}
                            label="Повторите пароль"
                            placeholder="Повторите пароль"
                            error={errors?.confirmPassword?.message}
                        />
                    )}
                />
            </Box>
            <Box mb={0.5}>
                <CustomAccordeon title="Как создать пароль">
                    <Box>
                        <Typography
                            variant="body2"
                            color="primary"
                        >
                            Для создания пароля разрешается применять следующие символы:
                        </Typography>
                        <ul
                            style={{
                                listStyle: 'outside',
                            }}
                        >
                            <li>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    Заглавные латинские буквы: от A до Z (26 символов)
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    Строчные латинские буквы: от a до z (26 символов)
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    Цифры от 0 до 9 (10 символов)
                                </Typography>
                            </li>
                            <li>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                >
                                    {
                                        'Символы: (пробел) ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [  ] ^ _` { | } ~ (33 символа)'
                                    }
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                </CustomAccordeon>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth
                >
                    Сохранить
                </Button>
            </Box>
        </form>
    );
};

export default ChangePasswordForm;
