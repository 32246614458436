import { createWithEqualityFn as create } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

import ChatsService from 'src/services/ChatsService';

import type { IChatBody } from 'src/types/Chats';

type ChatsStore = {
    chats: IChatBody[];
    setChats: (chats: IChatBody[]) => void;
    fetchChats: () => Promise<void>;
};

export default create<ChatsStore>(
    (set) => ({
        chats: [],

        setChats: (chats) => set({ chats }),

        fetchChats: async () => {
            try {
                const chats = await ChatsService.fetch();

                set({ chats });
                return Promise.resolve();
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
    }),
    shallow
);
