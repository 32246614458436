import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Chip, Box, Button, Typography, } from '@mui/material';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import useSubscriptionsStore from 'src/stores/subscriptions';
import hexContrastColor from 'hex-contrast-color';
import TransparentButton from 'src/components/TransparentButton';
import ArrowRight from 'src/icons/ArrowRight';
import Checked from 'src/icons/Checked';

import type { Subscription, CurrentSubscription } from 'src/types/Subscription';
import ROUTES from 'src/router/routes';
import formatNumber from 'src/utils/formatNumber';
import DialogConfirm from './DialogConfirm';

type Props = {
    subscription: Subscription & Pick<CurrentSubscription, 'duration_months' | 'end_date' | 'is_active' | 'is_recurring'>;
};

const CurrentTariff = ({ subscription }: Props) => {
    const [open, setIsOpen] = useState(false);
    const history = useHistory();
    const { setSelectedPlan, cancelSubscription } = useSubscriptionsStore(({ setSelectedPlan, cancelSubscription }) => ({ setSelectedPlan, cancelSubscription }));
    const color = '#' + subscription.color_code;
    const contrastColor = hexContrastColor(color);
    const { isLoading, isError, refetch } = useQuery({
        queryKey: 'cancelPayment',
        queryFn: () => cancelSubscription(),
        onSuccess: () => {
            enqueueSnackbar({ message: 'Подписка приостановлена', variant: 'success' });
        },
        enabled: false,
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar({ message: 'Произошла ошибка при отмене подписки', variant: 'error' });
        }
    }, [isError]);

    const date = dayjs(subscription.end_date).format('DD.MM.YYYY');

    const handleClickUpdateDuration = () => {
        setSelectedPlan(subscription.id);

        history.push({
            pathname: ROUTES.PAYMENT.path,
            state: { period: 12 },
        });
    }

    const handleCickCancel = () => {
        setIsOpen(true);
    };

    return (
        <>
            <DialogConfirm
                open={open}
                onClose={() => { setIsOpen(false);}}
                onAccept={() => { refetch(); }}
                date={date}
            />
            <Box
                display="block"
                component={'label'}
                border={'1px solid'}
                borderColor={'#E3ECF3'}
                bgcolor="background.default"
                borderRadius={4}
                p={1.5}
            >
                <Box mb={1.5}>
                    <Chip
                        color="info"
                        label={subscription.name}
                        size="medium"
                        style={{
                            backgroundColor: color,
                            color: contrastColor
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={0.5}
                    >
                        <Typography variant="body2">Оплата в месяц</Typography>
                        <Typography
                            variant="body2"
                            textAlign="right"
                        >
                            {formatNumber(subscription.price)} ₽
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={0.5}
                    >
                        <Typography variant="body2">Следующая оплата</Typography>
                        <Typography
                            variant="body2"
                            textAlign="right"
                        >
                            {subscription.is_recurring ? date : '-'}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="body2">Способ оплаты</Typography>
                        <Typography
                            variant="body2"
                            textAlign="right"
                        >
                            ЮKassa
                        </Typography>
                    </Box>
                </Box>
                <Box mb={2}>
                    <Box mb={0.5}>
                        <Typography
                            variant="body2"
                            color="secondary"
                        >
                            В подписку включено:
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="6px"
                    >
                        {subscription.bundles_limit > 0 && (
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="6px"
                            >
                                <Checked
                                    fillColor={color}
                                    fontColor={contrastColor}
                                />
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    {subscription.bundles_limit} подборки
                                </Typography>
                            </Box>
                        )}
                        {subscription.chats_limit > 0 && (
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="6px"
                            >
                                <Checked
                                    fillColor={color}
                                    fontColor={contrastColor}
                                />
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    {subscription.chats_limit} чатов
                                </Typography>
                            </Box>
                        )}
                        {subscription.keywords_limit > 0 && (
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="6px"
                            >
                                <Checked
                                    fillColor={color}
                                    fontColor={contrastColor}
                                />
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    {subscription.keywords_limit} ключевых слов
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {
                    subscription.duration_months < 12 && (
                        <Box mb={0.5}>
                            <Button
                                onClick={handleClickUpdateDuration}
                                variant="contained"
                                color="primary"
                                size="medium"
                                fullWidth
                                endIcon={
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={0.5}
                                    >
                                        <Chip
                                            color="default"
                                            label="30%"
                                            size="small"
                                        />
                                        <ArrowRight />
                                    </Box>
                                }
                            >
                                Перейти на годовой план
                            </Button>
                        </Box>
                    )
                }
                <Box>
                    <TransparentButton
                        disabled={isLoading || !subscription.is_active}
                        onClick={handleCickCancel}
                        variant="contained"
                        color="inherit"
                        size="medium"
                        fullWidth
                    >
                        {
                            subscription.is_active ? 'Приостановить подписку' : 'Подписка приостановлена'
                        }
                    </TransparentButton>
                </Box>
            </Box>
        </>
    );
};

export default CurrentTariff;
