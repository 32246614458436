export const Info = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4364_32772)">
            <g clipPath="url(#clip1_4364_32772)">
                <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="#1F1F1F" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.5416 8.60543C9.73927 7.68904 10.689 7 12.0004 7C13.4704 7 14.5004 7.97692 14.5004 9C14.5004 9.49493 14.4301 9.82047 14.2452 10.1236C14.05 10.4438 13.6973 10.7883 13.0293 11.2527L12.9802 11.2865C12.7926 11.4148 12.4363 11.6586 12.1274 12.0566C11.7797 12.5047 11.5004 13.1329 11.5004 14H12.5004C12.5004 13.3671 12.6981 12.9523 12.9174 12.6696C13.1264 12.4003 13.3631 12.2373 13.5494 12.1089L13.6002 12.0738C14.3043 11.5843 14.7945 11.1438 15.0991 10.6442C15.414 10.1276 15.5004 9.59848 15.5004 9C15.5004 7.26166 13.8442 6 12.0004 6C10.3817 6 8.89191 6.87475 8.56408 8.39457C8.50585 8.66451 8.67748 8.93053 8.94741 8.98876C9.21734 9.04698 9.48337 8.87536 9.5416 8.60543ZM12.0001 18C12.5524 18 13.0001 17.5523 13.0001 17C13.0001 16.4477 12.5524 16 12.0001 16C11.4478 16 11.0001 16.4477 11.0001 17C11.0001 17.5523 11.4478 18 12.0001 18Z" fill="#1F1F1F" />
        </g>
        <defs>
            <clipPath id="clip0_4364_32772">
                <rect width="24" height="24" fill="white" />
            </clipPath>
            <clipPath id="clip1_4364_32772">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="8"
            cy="8"
            r="7"
            fill="#E3ECF3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 5.75C8.41421 5.75 8.75 5.41421 8.75 5C8.75 4.58579 8.41421 4.25 8 4.25C7.58579 4.25 7.25 4.58579 7.25 5C7.25 5.41421 7.58579 5.75 8 5.75ZM8.5 7.5C8.5 7.22386 8.27614 7 8 7C7.72386 7 7.5 7.22386 7.5 7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V7.5Z"
            fill="black"
        />
    </svg>
);
